import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Colors } from '../../Config';
import { getSubscriptions, giveUserSubscription } from '../../Helpers/Data';

// Import components
import {
  SubscriptionCard,
  SubscriptionError,
  SubscriptionWarning,
} from '../../Components/Subscriptions';
import { Header, SideBar } from '../../Components/Misc';
import { CompleteModal } from '../../Components/ActionModals';
import useAuth from '../../Hooks/useAuth';

const Subscriptions = ({ history, userId }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showComplete, setShowComplete] = useState(false);
  const [showError, setShowError] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  const { user } = useAuth();
  let message = 'Subscription successfully started!';

  useEffect(() => {
    /* Get all available subscriptions from firebase. */
    const callGetSubscriptions = async () => {
      const subs = await getSubscriptions();
      setLoading(false);
      setSubscriptions(subs);
    };

    callGetSubscriptions();
  }, []);

  /* Navigation helper for our components. */
  const handleNavigation = () => {
    history.push('/dashboard');
  };

  /* Give subscription to a selected user. */
  const showSubscriptionWarning = (item) => {
    setSelectedItem(item);
    setShowWarning(true);
  };

  /* Actually give the subscription to the customer. */
  const disableWarningModal = async () => {
    setLoading(true);
    setShowWarning(false);
    giveSubscription();
  };

  /* Give the user a subscription via firebase functions. */
  const giveSubscription = async () => {
    const price = selectedItem.metadata.price;
    const priceId = selectedItem.metadata.priceId;
    const productDesc = selectedItem.description;
    const productId = selectedItem.id;
    const productName = selectedItem.name;
    const given = await giveUserSubscription(
      user.role,
      price,
      priceId,
      productDesc,
      productId,
      productName,
      userId,
    );

    if (given === 'SUCCESS') {
      setLoading(false);
      setShowComplete(true);
    } else {
      setError(given);
      setLoading(false);
      setShowError(true);
    }
  };

  /* Close all modals. */
  const closeModals = () => {
    setShowComplete(false);
    setShowError(false);
    setShowWarning(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <SideBar history={history} screen="All Subscriptions" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <Header history={history} screen="All Subscriptions" />

        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          {/* If no subscriptions, show a message saying so. */}
          {!subscriptions && !loading && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <h1>This user doesn't have any subscriptions yet.</h1>
            </div>
          )}

          {/* If something is loading. */}
          {loading && (
            <div
              style={{
                alignItems: 'center',
                backgroundColor: '#000000',
                opacity: 0.2,
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                position: 'absolute',
                width: '100vw',
              }}
            >
              <CircularProgress
                color="primary"
                style={{ color: Colors.primary, opacity: 1 }}
              />
            </div>
          )}

          {/* If user has subscriptions, add sub data to this component. */}
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {subscriptions &&
              subscriptions.length > 0 &&
              subscriptions.map((item, index) => (
                <SubscriptionCard
                  action={showSubscriptionWarning}
                  actionName="Add"
                  description={item.description}
                  item={item}
                  key={index}
                  subheader={item.metadata.price}
                  title={item.name}
                />
              ))}
          </div>

          {/* Show modals when necessary. */}
          {showComplete && (
            <CompleteModal
              action={handleNavigation}
              close={handleNavigation}
              message={message}
            />
          )}
          {showWarning && (
            <SubscriptionWarning
              action={disableWarningModal}
              close={closeModals}
              message="Warning, this subscription will start immediately, the default card
                attached to the customer's account will be charged."
            />
          )}
          {showError && <SubscriptionError close={closeModals} error={error} />}
        </div>
      </div>
    </div>
  );
};

/* Wrapper for using useParams hook. */
export default function AllSubscriptions({ history }) {
  let { userId } = useParams();
  return <Subscriptions history={history} userId={userId} />;
}
