import { useState } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  LinearProgress,
  Modal,
  Paper,
} from '@material-ui/core';
import { CustomTextField } from '../Custom/CustomForm';

const AppDeliveryModal = (props) => {
  const [googleLink, setGLink] = useState('');
  const [appleLink, setALink] = useState('');
  const [loading, setLoading] = useState(false);
  const [webLink, setWLink] = useState('');

  /*
    Fields to collect: user.name, google_play_link, apple_link, user.email, user.uid or user._id
    */

  const showLoading = () => {
    setLoading(true);
    sendEmail();
  };

  const sendEmail = async () => {
    const { showError, showComplete, user } = props;
    return await fetch(
      'https://us-central1-billing-portal-c099a.cloudfunctions.net/emailAppReady',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          apple_link: appleLink,
          google_play_link: googleLink,
          user,
          web_link: webLink,
        }),
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.message === 'SUCCESS') {
          setLoading(false);
          const message =
            response.message + ', they will receive an email shortly.';
          showComplete(message);
        } else {
          setLoading(false);
          showError(response.message);
        }
      })
      .catch((response) => {
        setLoading(false);
        showError(response.message);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={styles.modal}
      open={true}
      onClose={props.close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Paper elevation={3} style={styles.paper}>
          <h3 style={styles.textcenter}>
            Please enter the two mobile app links and the webapp link here.
          </h3>
          <h6 style={styles.textcenter}>
            Once you press submit, the customer will get an automated email
            saying their app is ready.
          </h6>
          <div style={styles.textwrapper}>
            <CustomTextField
              id="outlined-basic"
              variant="outlined"
              type="text"
              value={googleLink}
              placeholder="Google App Link"
              required
              style={styles.textfield}
              onChange={(e) => setGLink(e.target.value)}
            />
            <CustomTextField
              id="outlined-basic"
              variant="outlined"
              type="text"
              value={appleLink}
              placeholder="Apple App Link"
              required
              style={styles.textfield}
              onChange={(e) => setALink(e.target.value)}
            />
            <CustomTextField
              id="outlined-basic"
              variant="outlined"
              type="text"
              value={webLink}
              placeholder="Web Link"
              required
              style={styles.fullwidth}
              onChange={(e) => setWLink(e.target.value)}
            />
          </div>

          <hr style={styles.fullwidth} />
          {loading && (
            <LinearProgress color="primary" style={styles.fullwidth} />
          )}
          <div style={styles.buttoncontainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={props.close}
              style={styles.closebutton}
            >
              CLOSE
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={showLoading}
              style={styles.submitbutton}
            >
              SUBMIT
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: 600,
    padding: 30,
  },
  textcenter: {
    textAlign: 'center',
  },
  textwrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textfield: {
    marginBottom: 20,
    width: '100%',
  },
  fullwidth: {
    width: '100%',
  },
  buttoncontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  closebutton: {
    marginRight: 10,
    width: '80%',
  },
  submitbutton: {
    width: '80%',
  },
};

export default AppDeliveryModal;
