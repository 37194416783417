import {
  Backdrop,
  Button,
  Fade,
  LinearProgress,
  Modal,
  Paper,
} from '@material-ui/core';
import useAuth from '../../Hooks/useAuth';

/*
 * Confirmation modal to delete a user.
 */
const ConfirmDeleteUserModal = ({ close, submit, user }) => {
  // const [showAlert, setShowAlert] = useState(false);
  const visible = false;

  const { currentUser } = useAuth();

  /* Render text based on if this is a staff member or user account. */
  const renderText = () => {
    return user && user._id !== currentUser.uid
      ? 'Are you sure you want to delete this user?'
      : 'Are you sure you want to delete your account?';
  };

  /* Do any checks for user authenticity here. */
  const handleSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={true}
      onClose={close}
      BackdropComponent={Backdrop}>
      <Fade in={true}>
        <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
          <h1 style={{ textAlign: 'center' }}>{renderText()}</h1>
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <hr style={{ width: '100%' }} />
            {visible && (
              <LinearProgress color="secondary" style={{ width: '100%' }} />
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 20, width: '100%' }}
                onClick={close}>
                Close
              </Button>

              <Button
                variant="contained"
                color="secondary"
                style={{ width: '100%' }}
                type="submit">
                SUBMIT
              </Button>
            </div>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ConfirmDeleteUserModal;
