import React from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function SimpleSnackbar(props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.open}
      autoHideDuration={3000}
      onClose={() => props.close()}
      message={props.message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => props.close()}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
