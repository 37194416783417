import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();

export const auth = firebase.auth();

export const db = firebase.firestore();

export const appRef = db.collection('Apps');

export const messagesRef = db.collection('Messages');

export const metaRef = db.collection('Metadata');

export const servertime = firebase.firestore.FieldValue.serverTimestamp();

export const storageRef = firebase.storage().ref('Authoritek');

export const subscriptionRef = db.collection('Subscriptions');

export const userRef = db.collection('Users');
