import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getUser,
  getUserSubscriptions,
  removeUserSubscription,
} from '../../Helpers/Data';
import { auth } from '../../Api/FirebaseConfig';

// Import components
import { GenericAddButton, Header, SideBar } from '../../Components/Misc';
import {
  EditSubscriptionModal,
  SubscriptionError,
  SubscriptionWarning,
  UserSubscriptionCard,
} from '../../Components/Subscriptions';
import { CompleteModal } from '../../Components/ActionModals';

class ManageScriptions extends React.Component {
  /* Init basic state */
  state = { myRole: '', subscriptions: [], user: { business: '' } };

  componentDidMount() {
    // TODO: Should we send the subscriptions via props to increase performance? Or search for them here?
    this.getSubscriptions(this.props.userId);
  }

  /* Close all modals. */
  closeModals = () => {
    this.setState({
      editModal: false,
      showComplete: false,
      showError: false,
      showWarning: false,
    });
  };

  /* Actually delete the subscription from the customer. */
  disableWarningModal = async () => {
    this.setState({ loading: true, showWarning: false }, () =>
      this.removeSubscription(),
    );
  };

  /* Get all subscriptions of a user. */
  getSubscriptions = async (userId) => {
    const myId = auth.currentUser.uid;
    const userData = await getUser(userId);
    const myData = await getUser(myId);
    const subscriptions = await getUserSubscriptions(userId, 'Valid');

    this.setState({ myRole: myData.role, subscriptions, user: userData });
  };

  /* Navigation handler for sending user to another screen. */
  handleNavigation = (screen) => {
    this.props.history.push(`/${screen}/${this.props.userId}`);
  };

  /* Show toe "Edit-Subscription" Modal.*/
  showEditModal = (selectedSubscription) => {
    this.setState({ editModal: true, selectedSubscription });
  };

  /* Remove the selected subscription from the customer. */
  removeSubscription = async () => {
    const { selectedItem } = this.state;
    const { userId } = this.props;

    const removed = await removeUserSubscription(selectedItem, userId);

    if (removed === 'SUCCESS') {
      this.setState({ loading: false, showComplete: true });
    } else {
      this.setState({ error: removed, loading: false, showError: true });
    }
  };

  /* Show the complete modal when we need to. */
  showCompleteModal = (message) => {
    this.setState({ message, showComplete: true });
  };

  /* Show the error modal when need be. */
  showErrorModal = () => {
    const error = 'Something went wrong, please try again later.';
    this.setState({ error, showError: true });
  };

  /* Give subscription to a selected user. */
  showSubscriptionWarning = (item) => {
    this.setState({ selectedItem: item, showWarning: true });
  };

  /* Main render method of component. */
  render() {
    const {
      editModal,
      error,
      myRole,
      selectedSubscription,
      showComplete,
      showError,
      showWarning,
      subscriptions,
      user,
    } = this.state;
    let message = 'This subscription has been successfully ended.';
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}>
        <SideBar history={this.props.history} screen="Subscriptions" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}>
          <Header
            history={this.props.history}
            screen={`Subscriptions for ${user.business}`}
          />

          <div style={{ height: '100%', width: '100%' }}>
            {/* If no subscriptions, show a message saying so. */}
            {subscriptions.length === 0 && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <h1>This user doesn't have any subscriptions yet.</h1>
              </div>
            )}

            {/* If user has subscriptions, add sub data to this component. */}
            {subscriptions &&
              subscriptions.length > 0 &&
              subscriptions.map((item, index) => (
                <UserSubscriptionCard
                  action={this.showSubscriptionWarning}
                  actionName="Delete"
                  description={item.description}
                  edit={this.showEditModal}
                  item={item}
                  key={index}
                  metadata={item.metadata ? item.metadata : ''}
                  secondActionName="Edit"
                  status={item.appStatus}
                  subheader={item.amount}
                  title={item.name}
                />
              ))}

            {/* Add a subscription to this user. */}
            {myRole !== 'Customer' && (
              <div style={{ position: 'fixed', right: 20, bottom: 20 }}>
                <GenericAddButton
                  action={this.handleNavigation}
                  screen="subscriptions"
                  text="Give Subscription"
                />
              </div>
            )}

            {/* Show modals when necessary. */}
            {showComplete && (
              <CompleteModal
                action={this.handleNavigation}
                close={this.closeModals}
                message={message}
              />
            )}
            {showWarning && (
              <SubscriptionWarning
                action={this.disableWarningModal}
                close={this.closeModals}
                message="Warning, this subscription will terminate immediately. If you ever
                want to continue the subscription, contact development support."
              />
            )}
            {showError && (
              <SubscriptionError close={this.closeModals} error={error} />
            )}

            {/* Edit Subscription modals */}
            {editModal && (
              <EditSubscriptionModal
                action={this.showCompleteModal}
                close={this.closeModals}
                error={this.showErrorModal}
                item={selectedSubscription}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

/* We need to wrap our class component in a functional component in order to use the useParams hook to get the UserId */
export default function ManageUserSubscriptions(props) {
  let { userId } = useParams();
  return <ManageScriptions history={props.history} userId={userId} />;
}
