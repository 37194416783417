import { useState } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
} from '@material-ui/core';
import { updateUserStatus } from '../../Helpers/Data';

/* This modal should change the onboardingStatus of the selected user.
     Statuses: Started, onboarding, ready for sale, and paid. */
const ChangeStatusModal = (props) => {
  const [loading, setLoading] = useState(false);
  // const [selectedStatus, setSelectedStatus] = useState(null);
  const selectedStatus = '';

  /* Trigger the loading UI */
  const displayLoading = () => {
    setLoading(true);
    handleSubmit();
  };

  /* Update the customer's onboarding status in Firestore.
     Return with either a success message or error. Display on parent component UI. */
  const handleSubmit = async () => {
    const { showComplete, showError, user } = props.user;
    const updated = await updateUserStatus(user._id, selectedStatus);
    const message = `${user.name} has been updated.`;

    updated === 'SUCCESS' ? showComplete(message) : showError(updated);
  };

  /* Display the different status options. */
  const renderOptions = () => {
    return [
      <MenuItem key={0} value="Started">
        Started
      </MenuItem>,
      <MenuItem key={1} value="Onboarding">
        Onboarding
      </MenuItem>,
      <MenuItem key={2} value="Ready For Sale">
        Ready For Sale
      </MenuItem>,
      <MenuItem key={3} value="Paid">
        Paid
      </MenuItem>,
    ];
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={true}
      onClose={props.close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
          <h3 style={{ textAlign: 'center' }}>
            Please enter the two mobile app links and the webapp link here.
          </h3>
          <h6 style={{ textAlign: 'center' }}>
            Once you press submit, the customer will get an automated email
            saying their app is ready.
          </h6>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Actions
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Role"
                style={{ minWidth: 200 }}
              >
                {renderOptions()}
              </Select>
            </FormControl>
          </div>

          <hr style={{ width: '100%' }} />
          {loading && (
            <LinearProgress color="primary" style={{ width: '100%' }} />
          )}
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={props.close}
              style={{ marginRight: 10, width: '80%' }}
            >
              CLOSE
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={displayLoading}
              style={{ width: '80%' }}
            >
              SUBMIT
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ChangeStatusModal;
