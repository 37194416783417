import React, { useState } from 'react';
import { Collapse, IconButton, Link, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { CustomButton, CustomTextField } from '../Custom/CustomForm';
import logo from '../../Assets/Authoritek_Color.png';
import styles from '../../Styles/Auth';
import useAuth from '../../Hooks/useAuth';

const LoginModal = (props) => {
  const [alert, setAlert] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');

  const { login, logout } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let e = email.toLowerCase();
      e = e.trim();

      const user = await login(e, password);

      getUserData(user);
    } catch (err) {
      // TODO: For some reason, this catch wasn't executing on a failed auth call?
      console.error('Login Error:', err);
      setMessage(err.message);
      setAlert(true);
    }
  };

  const getUserData = async ({ role }) => {
    if (props.override && role === 'Admin') {
      props.overrideLogin();
      return null;
    }

    if (props.override && role !== 'Admin') {
      await logout();
      return null;
    }

    if (role === 'Admin' || role === 'Salesperson') {
      props.history.push('/dashboard');
    } else if (role !== 'Admin' || role !== 'Salesperson') {
      props.history.push('/profile');
    }
  };

  const goToSignup = (e) => {
    e.preventDefault();
    props.history.push('/setup-account');
  };

  return (
    <div style={styles.main}>
      <div style={styles.secondary}>
        {/* Alert snackbar if something happens during the sign up process. */}
        <Collapse
          in={alert}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
          }}>
          <Alert
            variant="filled"
            severity="error"
            style={{
              bottom: 20,
              left: '28%',
              position: 'absolute',
              right: '28%',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlert(false)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {message}
          </Alert>
        </Collapse>

        {/* Main container of the screen. Allows user to sign in to portal. */}
        <Paper elevation={3} style={styles.paper}>
          <form onSubmit={handleSubmit} style={styles.form}>
            <div>
              <img
                src={logo}
                alt="logo.png"
                width="100%"
                style={{ maxWidth: 450 }}
                height="100"
              />
            </div>
            <CustomTextField
              id="outlined-basic"
              variant="outlined"
              type="email"
              value={email}
              placeholder="Email"
              required
              style={styles.textfield}
              onChange={(e) => setEmail(e.target.value)}
            />
            <CustomTextField
              id="outlined-basic"
              variant="outlined"
              type="password"
              value={password}
              placeholder="Password"
              required
              style={styles.textfield}
              onChange={(e) => setPassword(e.target.value)}
            />
            <CustomButton
              variant="contained"
              color="default"
              type="submit"
              style={styles.loginbutton}>
              Login
            </CustomButton>

            <div>
              First time here?{' '}
              <Link href="/signup" onClick={goToSignup}>
                Sign up
              </Link>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

class Wrapper extends React.Component {
  render() {
    return <LoginModal history={this.props.history} />;
  }
}

export default Wrapper;
