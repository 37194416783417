const styles = {
  main: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  secondary: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    alignSelf: "center",
    justifySelf: "center",
    padding: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  textfield: {
    marginBottom: 20,
    width: "100%",
  },
  line: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  address: {
    width: "70%",
    marginRight: 5,
  },
  address2: {
    width: "30%",
  },
  city: {
    width: "43%",
  },
  hr: {
    width: "100%",
    marginBottom: 20,
  },
  progress: {
    width: "100%",
    marginBottom: 20,
  },
  submit: {
    width: "100%",
  },
  logintext: {
    marginTop: 20,
    // textShadow: "1px 1px 2px #000000",
  },
  loginbutton: {
    marginBottom: 20,
  },
};

export default styles;
