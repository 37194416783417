import React, { useState } from "react";

import {
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { stateArr } from "../../Helpers/ReusedVars";
import Colors from "../../Styles/Colors";

export default function SetupAppInfo(props) {
  const [street, setStreet] = useState(
    props.data.street ? props.data.street : ""
  );
  const [city, setCity] = useState(props.data.city ? props.data.city : "");
  const [addressState, setAddressState] = useState(
    props.data.addressState ? props.data.addressState : "AL"
  );
  const [zipcode, setZipcode] = useState(
    props.data.zipcode ? props.data.zipcode : ""
  );

  const handleStateUpdate = (val, type) => {
    switch (type) {
      case "Street":
        setStreet(val);
        break;
      case "City":
        setCity(val);
        break;
      case "State":
        setAddressState(val);
        break;
      case "Zipcode":
        setZipcode(val);
        break;
      default:
        console.log("Nothing should update");
    }
  };

  /* When we make our network request to save this user as a firebase user, show some loading. */
  const showLoading = (e) => {
    e.preventDefault();
    // props.loading(true);
    handleSubmit();
  };

  /* Save our state variables as an object and ship it up to the parent component to be saved. */
  const handleSubmit = () => {
    props.callCompletionHandler({
      street,
      city,
      addressState,
      zipcode,
    });
  };

  return (
    <form onSubmit={showLoading} style={{ width: "80%" }}>
      <TextField
        fullWidth
        placeholder="Address"
        type="text"
        value={street}
        onChange={(e) => handleStateUpdate(e.target.value, "Street")}
        variant="outlined"
        style={{ marginBottom: 10, marginTop: 10 }}
        required
      />

      <TextField
        fullWidth
        placeholder="City"
        type="text"
        value={city}
        onChange={(e) => handleStateUpdate(e.target.value, "City")}
        variant="outlined"
        style={{ marginBottom: 10 }}
        required
      />

      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: 10,
          width: "100%",
        }}
      >
        <FormControl
          style={{
            marginRight: 10,
            width: "100%",
          }}
          variant="outlined"
        >
          <Select
            style={{ height: "100%" }}
            variant="outlined"
            onChange={(e) => handleStateUpdate(e.target.value, "State")}
            value={addressState}
          >
            {stateArr.map((element) => {
              return (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          placeholder="Zipcode"
          value={zipcode}
          style={{ marginLeft: 10, width: "100%" }}
          onChange={(e) => handleStateUpdate(e.target.value, "Zipcode")}
          variant="outlined"
          required
        />
      </div>

      {props.loading && <LinearProgress color="primary" />}

      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
          width: "100%",
        }}
      >
        <Button
          disabled={props.loading}
          variant="contained"
          style={{ width: "48%" }}
          color="primary"
          onClick={() =>
            props.saveAppInfoData({
              street,
              city,
              addressState,
              zipcode,
            })
          }
        >
          Back
        </Button>
        <Button
          disabled={props.loading}
          variant="contained"
          style={{
            backgroundColor: Colors.moss,
            color: Colors.white,
            width: "48%",
          }}
          type="submit"
        >
          Continue
        </Button>
      </div>
    </form>
  );
}
