// Material UI custom stuff
import { withStyles } from "@material-ui/core/styles";
import colors from "../../Styles/Colors";
import { Button, TextField, Select } from "@material-ui/core";

/*
 * This file contains our custom material-ui components.
 * If we add more down the road, please throw them in here.
 */

// Light textfield to stand out against a dark background.
export const CustomTextField = withStyles({
  root: {
    backgroundColor: colors.primarybackground,
    borderRadius: 5,
  },
})(TextField);

// Tealish button with white text.
export const CustomButton = withStyles({
  root: {
    backgroundColor: colors.aqua,
    color: colors.white,
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
  },
})(Button);

// Sand button with white text.
export const CustomButton2 = withStyles({
  root: {
    backgroundColor: colors.buttonsecondary,
    color: colors.white,
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
  },
})(Button);

// Tealish button with dark text.
export const CustomButtonDark = withStyles({
  root: {
    backgroundColor: colors.buttonprimary,
    color: colors.white,
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
  },
})(Button);

// Sand button with dark text.
export const CustomButton2Dark = withStyles({
  root: {
    backgroundColor: colors.buttonsecondary,
    color: colors.white,
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
  },
})(Button);

// Light select component.
export const CustomSelect = withStyles({
  root: {
    backgroundColor: colors.white,
    borderRadius: 5,
    width: "100%",
  },
  label: {
    textAlign: "left",
  },
})(Select);

// export const CustomSwitch = withStyles({
//   switchBase: {
//     "&$checked": {
//       color: "#E6C4A6",
//     },
//   },
// })(Switch);
