import React from "react";

export default function ContactBody() {
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>
        If you have any questions, please send us an email by filling out this
        form.
      </h2>
    </div>
  );
}
