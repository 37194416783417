import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Import Screens
import { AdminDashboard, SiteSettings, Users } from './Screens/Admin';
import { Login } from './Screens/Auth';
import {
  CompletedSetup,
  Contact,
  Farewell,
  Loading,
  More,
  // Payment,
} from './Screens/Misc';
import { CustomerProcess } from './Screens/Processes';
import { UserProfile } from './Screens/Profile';
// import { MyRevenue } from "./Screens/Sales";
import {
  AllSubscriptions,
  ManageUserSubscriptions,
} from './Screens/Subscriptions';

// import { AllMessages } from "./Screens/Messages";
import { SetupBusinessInfo } from './Screens/Onboarding';
import { AuthProvider } from './Context/auth';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route component={Loading} exact path="/" />
          <Route component={Login} exact path="/login" />
          <Route component={CompletedSetup} exact path="/setup-completion" />
          {/* <Route component={SignUp} exact path="/signup" />
        <Route component={Payment} exact path="/payment" /> */}
          <Route component={CustomerProcess} exact path="/our-process" />
          <Route component={Contact} exact path="/contact" />
          <Route component={AdminDashboard} exact path="/dashboard" />
          <Route component={SiteSettings} exact path="/site-settings" />
          {/* <Route component={MyRevenue} exact path="/my-revenue" />
        <Route component={AllMessages} exact path="/two-factor" /> */}

          {/* Our stuff for the new onboarding flow. */}
          <Route component={SetupBusinessInfo} exact path="/setup-account" />
          <Route component={UserProfile} exact path="/profile" />
          {/* Subscriptions */}
          <Route
            component={ManageUserSubscriptions}
            exact
            path="/manage-subscriptions/:userId"
          />
          <Route
            component={AllSubscriptions}
            exact
            path="/subscriptions/:userId"
          />
          {/* Misc screens */}
          <Route component={Farewell} exact path="/farewell" />
          <Route component={More} exact path="/more" />
          <Route component={Users} exact path="/users" />
          <Route component={Loading} path="/" />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
