const Colors = {
  moss: "#3ABB94",
  aqua: "#224091",
  black: "#121212",
  darkgray: "#4F4F4F",
  gray1: "#828282",
  gray2: "#BDBDBD",
  gray3: "#E0E0E0",
  gray4: "#F2F2F2",
  white: "#FFFFFF",
  red: "#BB3A#A",
  gold: "#F2C94C",
  cyan: "#1ecbe1",
};

export default Colors;
