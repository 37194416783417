import React from 'react';
import { Button } from '@material-ui/core';

export default function ViewProcessButton(props) {
  const checkProcess = (process) => {
    if (process === 'dev') {
      // TODO: navigate through dev process.
    } else if (process === 'internal') {
      // TODO: navigate through our internal process
    } else {
      // Showing the customer process.
      props.history.push('/our-process');
    }
  };

  return (
    <Button
      color="primary"
      onClick={() => checkProcess(props.process)}
      style={{ width: '100%' }}
      variant="contained">
      View Our Process
    </Button>
  );
}
