import { useState } from 'react';
import { Button } from '@material-ui/core';
import { emailRemoveUser, removeUser } from '../../Helpers/Data';
import useAuth from '../../Hooks/useAuth';

// Import components
import { ConfirmDeleteUserModal } from './';

const DeleteUserButton = ({ history, user }, ...props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { currentUser } = useAuth();

  /* Show the confirmation modal to delete a user. */
  const deleteUserConfirmation = async () => {
    setShowDeleteModal(true);
  };

  /* Remove the user from the system. */
  const deleteUser = async () => {
    if (user._id !== currentUser.uid) {
      const deleted = await removeUser(user._id);

      if (deleted === 'SUCCESS') {
        // TODO: make this alert prettier and navigate user back a screen. (use snackbar)
        alert(`${user.name} has been deleted!`);
        history.goBack();
      } else {
        // TODO: make this alert prettier. (use snackbar)
        alert('Something went wrong...');
      }
    } else {
      await emailRemoveUser(user);
      history.push('/farewell');
    }
  };

  /* Close all modals from our screen */
  const closeModals = () => {
    setShowDeleteModal(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Button
        color="secondary"
        onClick={deleteUserConfirmation}
        style={{ width: '100%' }}
        variant="contained">
        Delete Account
      </Button>
      {showDeleteModal && (
        <ConfirmDeleteUserModal
          {...props}
          close={closeModals}
          submit={deleteUser}
        />
      )}
    </div>
  );
};

export default DeleteUserButton;
