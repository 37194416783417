import React from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { auth } from '../../Api/FirebaseConfig';
import { getUser } from '../../Helpers/Data';

// Import Components
import {
  AppDeliveryModal,
  ChangeStatusModal,
} from '../../Components/ActionModals';
import {
  ConfirmDeleteUserModal,
  DeleteUserButton,
  SignOutButton,
} from '../../Components/Auth';
import {
  Header,
  GenericSnackbar,
  SideBar,
  ViewProcessButton,
} from '../../Components/Misc';
import { UserAccount } from '../../Components/Users';
import { emailRemoveUser, removeUser } from '../../Helpers/Data';

// Init listener for global scope.
let listener = null;

export default class UserProfile extends React.Component {
  state = { myUserInfo: null, ready: false, url: null, user: this.props.user };

  componentDidMount() {
    listener = auth.onAuthStateChanged((user) => {
      if (user) {
        this.checkForSessionUrl();
      } else {
        console.error('NO USER');
        this.props.history.push('/');
      }
    });

    // Wait 2 seconds to unsubscribe to authentication listener.
    // setTimeout(() => {
    //   listener();
    // }, 2000);
  }

  /* Basically, we need to check if the user has a "session url" from stripe in order to view the customer portal.
     If they don't, we need to create a new instance for them. */
  async checkForSessionUrl() {
    // This ternary specifies if this is our account or if someone is viewing the data of another account.
    let user = this.props.location.user
      ? this.props.location.user
      : auth.currentUser;

    if (user.email !== auth.currentUser.email) {
      user.uid = this.props.location.user
        ? this.props.location.user._id
        : auth.currentUser.uid;
    }

    const userinfo = await getUser(user.uid);
    const myUserInfo = await getUser(auth.currentUser.uid);

    // Check if user has this data in firestore already.
    if (userinfo.sessionUrl && userinfo.customerId) {
      this.setState({
        customerId: userinfo.customerId,
        myUserInfo,
        user: userinfo,
      });
    } else {
      // User does not have data yet,
      // userRef
      //   .where("_id", "==", auth.currentUser.uid)
      //   .onSnapshot((snapshot) => {
      //     snapshot.docChanges().forEach((change) => {
      //       if (change.type === "modified") {
      //         let userinfo = change.doc.data();
      //         if (userinfo.sessionUrl) {
      //           this.setState({
      //             customerId: userinfo.customerId,
      //             url: userinfo.sessionUrl,
      //             user: userinfo,
      //           });
      //         }
      //       }
      //     });
      //   });

      // User is an "offline" subscriber
      this.setState({ user: userinfo });
    }
  }

  /* Check if the authed user is assigned to this user. (If I helped them create an account, I can edit their data.) */
  checkIfDisabled = (user) => {
    if (user.uid || user._id) {
      const myId = auth.currentUser.uid;
      const myRole = this.state.myUserInfo.role ?? 'Customer';

      if (myRole === 'Customer') {
        return false;
      }

      // Admin's can manage whoever.
      if (myRole === 'Admin') {
        return false;
      } else if (user.customerId && myRole === 'Admin') {
        return false;
      } else if (user.customerId && auth.currentUser.uid === user._id) {
        // If I'm a customer and I have a stripe account, I should be able to view it.
        return false;
      } else {
        // If I'm not an admin. If I'm the affiliate of this user, I can manage whatever.

        return user.customerId && myId === user.affiliateId ? false : true;
      }
    } else {
      // No user? Disable any button.
      return true;
    }
  };

  /* Close all modals and snackbars on UI. */
  closeModals = () => {
    this.setState({
      message: '',
      showChangeStatusModal: false,
      showDeleteModal: false,
      showModal: false,
      snackbar: false,
    });
  };

  /* When the component is unmounting, disable the listener. */
  componentWillUnmount() {
    listener();
  }

  /* Delete the selected account if the user wishes to do so. */
  /* Remove the user from the system. */
  deleteUser = async () => {
    const user = this.props.user;

    if (user._id !== auth.currentUser.uid) {
      const deleted = await removeUser(user._id);

      if (deleted === 'SUCCESS') {
        // TODO: make this alert prettier and navigate user back a screen. (use snackbar)
        alert(`${user.name} has been deleted!`);
        this.props.history.goBack();
      } else {
        // TODO: make this alert prettier. (use snackbar)
        alert('Something went wrong...');
      }
    } else {
      await emailRemoveUser(user);
      this.props.history.push('/farewell');
    }
  };

  viewFinancialInfo() {
    const { customerId } = this.state;

    const body = { customerId, message: 'Made it to function' };

    fetch(
      'https://us-central1-billing-portal-c099a.cloudfunctions.net/createStripeSession',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ body }),
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        window.open(response.sessionUrl, '_self'); // TODO: Change blank to _self to open customer portal in current tab.
      })
      .catch((error) => {
        console.error('CREATE STRIPE SESSION ERROR', error);
      });
  }

  /* Helper to check if we can delete this user or not. */
  canDeleteUser(user) {
    // If I'm an admin, I can delete someone
    const myRole = this.state.myUserInfo.role ?? 'Customer';

    if (myRole === 'Customer') {
      return false;
    }
    if (myRole === 'Admin') {
      return true;
    } else if (
      user._id &&
      auth.currentUser.uid !== user._id &&
      auth.currentUser.uid === user.affiliateId
    ) {
      // I'm the affiliate of this user account, I can delete them.
      return true;
    }

    // Else, don't show this button.
    return false;
  }

  /* Helper to check if authenticated user is the same as the data on screen. */
  renderBillingName(user) {
    if (user._id) {
      return user._id === auth.currentUser.uid
        ? 'Manage My Billing'
        : 'View Billing Info';
    }
  }

  /* Check to see if the current signed in user is the profile user. */
  checkIfDisabled = () => {
    const me = auth.currentUser.uid;
    const { user } = this.state;
    return user._id == me;
  };

  /* Render the action buttons in our select component at the bottom of the screen. */
  renderSelectOptions = () => {
    const { history } = this.props;
    const { user } = this.state;

    if (user._id || user.uid) {
      if (user._id !== auth.currentUser.uid) {
        // A staff member is visiting this user.
        if (!this.checkIfDisabled(user) && user.paymentOption === 'Stripe') {
          return [
            <MenuItem
              key={0}
              onClick={() => history.push(`/manage-subscriptions/${user._id}`)}>
              Manage User Subscriptions
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => this.setState({ showChangeStatusModal: true })}>
              Change Customer Status
            </MenuItem>,
            <MenuItem key={2} onClick={() => this.viewFinancialInfo()}>
              Manage Billing Info
            </MenuItem>,
            <MenuItem key={3} onClick={() => this.displayDeliveryModal()}>
              Send "Your App Is Ready!" Email
            </MenuItem>,
          ];
        } else if (
          !this.checkIfDisabled(user) &&
          user.paymentOption === 'Offline'
        ) {
          return [
            <MenuItem
              key={0}
              onClick={() => history.push(`/manage-subscriptions/${user._id}`)}>
              Manage User Subscriptions
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => this.setState({ showChangeStatusModal: true })}>
              Change Customer Status
            </MenuItem>,
            <MenuItem key={3} onClick={() => this.displayDeliveryModal()}>
              Send "Your App Is Ready!" Email
            </MenuItem>,
          ];
        } else {
          return [
            <MenuItem
              key={4}
              onClick={() => history.push(`/manage-subscriptions/${user._id}`)}>
              Manage User Subscriptions
            </MenuItem>,
            <MenuItem
              key={5}
              onClick={() => this.setState({ showChangeStatusModal: true })}>
              Change Customer Status
            </MenuItem>,
            <MenuItem key={6} onClick={() => this.displayDeliveryModal()}>
              Send "Your App Is Ready!" Email
            </MenuItem>,
          ];
        }
      } else {
        // If the user is a customer and this is their own account.

        if (user.paymentOption === 'Stripe') {
          return [
            // <MenuItem key={7}>
            //   <Button
            //     color="primary"
            //     onClick={() => this.viewFinancialInfo()}
            //     style={{ width: "100%" }}
            //     variant="contained"
            //   >
            //     View Billing Info
            //   </Button>
            // </MenuItem>,
            <MenuItem key={8}>
              <Button
                color="primary"
                onClick={() => history.push('/contact')}
                style={{ width: '100%' }}
                variant="contained">
                Contact Us
              </Button>
            </MenuItem>,
            // <MenuItem key={9}>
            //   <ViewProcessButton
            //     history={this.props.history}
            //     process="customer"
            //     style={{ width: "100%" }}
            //   />
            // </MenuItem>,
            <MenuItem key={10}>
              <SignOutButton history={history} />
            </MenuItem>,
            // <MenuItem key={11}>
            //   <DeleteUserButton history={history} user={user} />
            // </MenuItem>,
          ];
        } else {
          return [
            <MenuItem key={7}>
              <Button
                color="primary"
                onClick={() =>
                  history.push(`/manage-subscriptions/${user._id}`)
                }
                disabled={this.checkIfDisabled()}
                style={{ width: '100%' }}
                variant="contained">
                View Subscriptions
              </Button>
            </MenuItem>,
            <MenuItem key={8}>
              <Button
                color="primary"
                onClick={() => history.push('/contact')}
                style={{ width: '100%' }}
                variant="contained">
                Contact Us
              </Button>
            </MenuItem>,
            <MenuItem key={9}>
              <ViewProcessButton
                history={history}
                process="customer"
                style={{ width: '100%' }}
              />
            </MenuItem>,
            <MenuItem key={10}>
              <SignOutButton history={history} />
            </MenuItem>,
            <MenuItem key={11}>
              <DeleteUserButton history={history} user={user} />
            </MenuItem>,
          ];
        }
      }
    }
  };

  /* Show the "send app ready email" modal. */
  displayDeliveryModal = () => {
    this.setState({ message: '', showModal: true, snackbar: false });
  };

  /* Show our generic snackbar. */
  displaySnackbar = (message) => {
    this.setState({ message, showModal: false, snackbar: true });
  };

  render() {
    const { history } = this.props;
    const {
      message,
      showChangeStatusModal,
      showDeleteModal,
      showModal,
      snackbar,
      user,
    } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}>
        <SideBar history={history} screen="My Profile" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}>
          <Header history={history} screen="My Profile" />

          {user && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 50,
                width: '100%',
              }}>
              <UserAccount
                renderOptions={this.renderSelectOptions}
                user={user}
              />
            </div>
          )}

          {/* Display our popups and modals. */}
          {showChangeStatusModal && (
            <ChangeStatusModal
              close={this.closeModals}
              showError={this.displaySnackbar}
              showComplete={this.displaySnackbar}
              user={user}
            />
          )}
          {showModal && (
            <AppDeliveryModal
              close={this.closeModals}
              showError={this.displaySnackbar}
              showComplete={this.displaySnackbar}
              user={user}
            />
          )}

          {showDeleteModal && (
            <ConfirmDeleteUserModal
              close={this.closeModals}
              props={this.props}
              submit={this.deleteUser}
            />
          )}

          {snackbar && (
            <GenericSnackbar
              close={this.closeModals}
              message={message}
              open={snackbar}
            />
          )}

          {/* Show an alert at the bottom of the screen if a customer needs to setup their billing still. */}
          {/* {user &&
            user.role === "Customer" &&
            user.paymentOption === "Stripe" && (
              <GenericAlert
                close={this.closeModals}
                text="You haven't setup your billing yet. Please set this up by clicking 'Actions' then 'View Billing Info."
              />
            )} */}
        </div>
      </div>
    );
  }
}
