import { appRef, subscriptionRef, userRef } from "../Api/FirebaseConfig";

/* Get a count of all apps from our database. */
export async function getAppCount() {
  return await appRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get a count of all ACTIVE subscriptions from our database. */
export async function getActiveSubscriptionCount() {
  return await subscriptionRef
    .where("appStatus", "!=", "Suspended")
    .get()
    .then((snapshot) => {
      return snapshot.size;
    });
}

/* Get a count of all subscriptions from our database. */
export async function getSubscriptionCount() {
  return await subscriptionRef.get().then((snapshot) => {
    return snapshot.size;
  });
}

/* Get a count of all Customers from our database. */
export async function getCustomerCount() {
  return await userRef
    .where("role", "==", "Customer")
    .get()
    .then((snapshot) => {
      return snapshot.size;
    });
}
