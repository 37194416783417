/* This file is a "true-all" master toggle configuration file.
    
   Essentially, if an Admin wants to, they can hide certain features from all user's of the system.
   Ex: Can toggle all subscriptions so no one can give our more apps. 
*/

import { metaRef } from '../Api/FirebaseConfig';

/* Get all metadata from firestore. */
export async function getMetadata() {
  let temp = [];
  const metadata = await metaRef.get();

  metadata.forEach((doc) => {
    temp.push(doc.data());
  });

  return temp;
}

/* Toggle subscriptions from being seen by anyone other than Admin's */
export async function toggleAllSubscriptions() {
  const subVal = await metaRef.doc('Subscriptions').get().data();
  return await subVal
    .doc('Subscriptions')
    .update({ hidden: !subVal })
    .then(() => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}

/* Update metadata values from the master metadata toggle. */
export async function updateMetadata(data) {
  for (let i = 0; i < data.length; i++) {
    await metaRef.doc(data[i]._id).update({ hidden: data[i].hidden });
  }
  return 'SUCCESS';
}
