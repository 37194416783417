import React, { useState } from "react";

// Import components
import {
  ContactBody,
  ContactForm,
  GenericSnackbar,
  Header,
  SideBar,
} from "../../Components/Misc";

export default function Contact(props) {
  const [message, setMessage] = useState("");
  const [snackbar, setSnack] = useState(false);

  /* Hide the snackbar. */
  const hideSnackbar = () => {
    setMessage("");
    setSnack(false);
  };

  /* Show the snackbar */
  const showSnackbar = (message) => {
    setMessage(message);
    setSnack(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={props.history} screen="Contact" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header history={props.history} screen="Contact" />
        <div
          style={{
            marginTop: 100,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ContactBody />
          <ContactForm history={props.history} show={showSnackbar} />

          {snackbar && (
            <GenericSnackbar
              close={hideSnackbar}
              message={message}
              open={snackbar}
            />
          )}
        </div>
      </div>
    </div>
  );
}
