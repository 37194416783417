import React from "react";
import { Backdrop, Button, Fade, Modal, Paper } from "@material-ui/core";

export default function SubscriptionWarning(props) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={true}
      onClose={() => props.close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
          <h3 style={{ textAlign: "center" }}>{props.message}</h3>
          <hr style={{ width: "100%" }} />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => props.close()}
              style={{ marginRight: 20, width: "100%" }}
            >
              CLOSE
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => props.action()}
              style={{ width: "100%" }}
            >
              PROCEED
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
}
