import { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Colors } from '../../Config';
import {
  getAppCount,
  getActiveSubscriptionCount,
  getSubscriptionCount,
  getCustomerCount,
} from '../../Helpers/Analytics';
import { CircularProgress, Fade } from '@material-ui/core';

const AnalyticsModule = () => {
  // const [appCount, setAppCount] = useState(null);
  // const [activeSubCount, setActiveSubCount] = useState(null);
  // const [custCount, setCustCount] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [subCount, setSubCount] = useState(null);
  // const [total, setTotal] = useState(null);

  /* When component mounts, get analytics data. */
  useEffect(() => {
    getData();
  }, []);

  /* Grab all the data we possibly can. */
  const getData = async () => {
    const ac = await getAppCount();
    const asc = await getActiveSubscriptionCount();
    const sc = await getSubscriptionCount();
    const cc = await getCustomerCount();

    const d = [
      { number: 1, amount: ac, category: 'Apps' },
      { number: 2, amount: asc, category: 'Current Subscriptions' },
      { number: 3, amount: sc, category: 'All Subscriptions' },
      { number: 4, amount: cc, category: 'Customers' },
    ];

    // const t = ac + asc + sc + cc;
    // setAppCount(ac);
    // setActiveSubCount(asc);
    // setSubCount(sc);
    // setCustCount(cc);
    // setTotal(t);
    setData(d);
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: Colors.moss,
        paddingBottom: 20,
        marginTop: 0,
        width: '100%',
      }}>
      <div style={{ height: '100%', paddingLeft: 30, paddingRight: 30 }}>
        {/* While we wait for our data... */}
        {loading && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: 500,
              justifyContent: 'center',
              maxWidth: 600,
            }}>
            <CircularProgress size={100} />
          </div>
        )}

        {!loading && (
          <Fade in={true}>
            <>
              <h1>Analytics</h1>
              <div
                style={{
                  backgroundColor: '#FFF',
                  borderRadius: 10,
                  height: 300,
                  padding: 20,
                  maxWidth: 600,
                }}>
                <ResponsiveBar
                  data={data}
                  keys={['amount']}
                  indexBy="category"
                  margin={{ top: 50, right: 100, bottom: 50, left: 130 }}
                  padding={0.3}
                  layout="horizontal"
                  colors={{ scheme: 'nivo' }}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>
            </>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default AnalyticsModule;
