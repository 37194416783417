import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Colors } from "../../Config";

export default function ButtonNavigators(props) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginLeft: 50,
        marginRight: 10,
      }}
    >
      <ArrowBackIosIcon
        onClick={() => props.history.goBack()}
        style={{ color: Colors.primary, cursor: "pointer" }}
      />
      <ArrowForwardIosIcon
        onClick={() => props.history.goForward()}
        style={{ color: Colors.primary, cursor: "pointer" }}
      />
    </div>
  );
}
