import React from 'react';
import styles from '../../Styles/More';
import { Header, SideBar } from '../../Components/Misc';

export default function More(props) {
  return (
    <div style={styles.main}>
      <SideBar history={props.history} screen="Info" />
      <div style={styles.secondary}>
        <Header history={props.history} screen="Info" />
        <div style={styles.linkscontainer}>
          {/* Terms link */}
          <div>
            <a
              style={styles.link}
              href="https://www.authoritek.com/terms-of-use">
              <h1 style={styles.linktitle}>Terms And Conditions</h1>
            </a>
          </div>
          {/* Privacy policy */}
          <div>
            <a
              style={styles.link}
              href="https://www.authoritek.com/privacy-policy">
              <h1 style={styles.linktitle}>Privacy Policy</h1>
            </a>
          </div>
          {/* Parent site */}
          <div>
            <a style={styles.link} href="https://www.authoritek.com">
              <h1 style={styles.linktitle}>Parent Site</h1>
            </a>
          </div>
          {/* Help link */}
          <div>
            <a style={styles.link} href="https://www.authoritek.com/contact">
              <h1 style={styles.linktitle}>Help</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
