import { useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader } from 'react-pro-sidebar';
import { Avatar } from '@material-ui/core';
import {
  Dashboard,
  Group,
  HelpOutline,
  Info,
  ToggleOn,
} from '@material-ui/icons';
import { auth } from '../../Api/FirebaseConfig';
import Colors from '../../Styles/Colors';
import 'react-pro-sidebar/dist/css/styles.css';
import useAuth from '../../Hooks/useAuth';

const SideBar = ({ history, screen }) => {
  const [name, setName] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    renderName();
  }, []);

  /* Render Admin header options */
  const renderHeaderOptions = () => {
    const role = user.role;

    if (role === 'AccountHolder' || role === 'Salesperson') {
      return [
        <MenuItem
          icon={<Dashboard />}
          key={0}
          onClick={() => history.push('/dashboard')}
        >
          <p
            style={
              screen === 'Dashboard'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Dashboard
          </p>
        </MenuItem>,
        // <MenuItem
        //   icon={<MonetizationOn />}
        //   key={1}
        //   onClick={() => history.push("/my-revenue")}
        // >
        //   <p
        //     style={
        //       screen === "My Revenue"
        //         ? {
        //             color: Colors.moss,
        //             fontWeight: "bold",
        //             marginTop: 0,
        //             marginBottom: 0,
        //           }
        //         : { marginTop: 0, marginBottom: 0 }
        //     }
        //   >
        //     Sales
        //   </p>
        // </MenuItem>,
        <MenuItem
          icon={<Group />}
          key={2}
          onClick={() => history.push('/users')}
        >
          <p
            style={
              screen === 'All Users'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Users
          </p>{' '}
        </MenuItem>,
        <MenuItem icon={<Info />} key={3} onClick={() => history.push('/more')}>
          <p
            style={
              screen === 'Info'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Info
          </p>
        </MenuItem>,
      ];
    } else if (role === 'Admin') {
      return [
        <MenuItem
          icon={<Dashboard />}
          key={4}
          onClick={() => history.push('/dashboard')}
        >
          <p
            style={
              screen === 'Dashboard'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Dashboard
          </p>
        </MenuItem>,
        // <MenuItem
        //   icon={<MonetizationOn />}
        //   key={5}
        //   onClick={() => history.push("/my-revenue")}
        // >
        //   <p
        //     style={
        //       screen === "My Revenue"
        //         ? {
        //             color: Colors.moss,
        //             fontWeight: "bold",
        //             marginTop: 0,
        //             marginBottom: 0,
        //           }
        //         : { marginTop: 0, marginBottom: 0 }
        //     }
        //   >
        //     Sales
        //   </p>
        // </MenuItem>,
        <MenuItem
          icon={<ToggleOn />}
          key={6}
          onClick={() => history.push('/site-settings')}
        >
          <p
            style={
              screen === 'Settings'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Settings
          </p>
        </MenuItem>,
        <MenuItem
          icon={<Group />}
          key={7}
          onClick={() => history.push('/users')}
        >
          <p
            style={
              screen === 'All Users'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Users
          </p>
        </MenuItem>,
        <MenuItem icon={<Info />} key={8} onClick={() => history.push('/more')}>
          <p
            style={
              screen === 'Info'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Info
          </p>
        </MenuItem>,
      ];
    } else {
      return [
        <MenuItem
          icon={<HelpOutline />}
          key={9}
          onClick={() => history.push('/contact')}
        >
          <p
            style={
              screen === 'Contact'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Contact
          </p>
        </MenuItem>,
        <MenuItem
          icon={<Info />}
          key={10}
          onClick={() => history.push('/more')}
        >
          <p
            style={
              screen === 'Info'
                ? {
                    color: Colors.moss,
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 0,
                  }
                : { marginTop: 0, marginBottom: 0 }
            }
          >
            Info
          </p>
        </MenuItem>,
      ];
    }
  };

  /* Render the user's name after we know they're signed in. */
  const renderName = () => {
    auth.onAuthStateChanged((userObj) => {
      if (userObj) {
        setName(userObj.displayName);
      }
    });
  };

  return (
    <div>
      <ProSidebar>
        <SidebarHeader style={{ padding: 10 }}>
          <div
            style={{
              display: 'flex',
              // flexDirection: "row",
              alignItems: 'center',
            }}
          >
            <div>
              <Avatar
                alt="Portal-Logo"
                src="https://firebasestorage.googleapis.com/v0/b/billing-portal-c099a.appspot.com/o/Authoritek%2FDefaults%2FAuthoritek_ProfileIcon.png?alt=media&token=1dff04ba-dfee-4676-94ec-e45d76cf9168"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: 20,
              }}
            >
              <p
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              >
                Authoritek
              </p>
              <p>{name}</p>
            </div>
          </div>
        </SidebarHeader>
        <Menu style={{ padding: 20 }}>{renderHeaderOptions()}</Menu>
      </ProSidebar>
    </div>
  );
};

export default SideBar;
