import React from 'react';
import { Button, LinearProgress, Paper, TextField } from '@material-ui/core';
import { auth } from '../../Api/FirebaseConfig';

export default class ContactForm extends React.Component {
  state = { text: '', subject: '' };

  /* Onsubmit handler for sending emails */
  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // Call Sendgrid endpoint here
      const { text, subject } = this.state;
      const email = auth.currentUser.email;

      await fetch(
        'https://us-central1-billing-portal-c099a.cloudfunctions.net/sendMail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({ email, text, subject }),
        },
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (!response.error) {
            this.props.show('Your message has been submitted!');
          } else {
            this.props.show(
              'Something went wrong on our end. Please try again later.',
            );
          }
        })
        .catch((error) => {
          console.error('Email Error:', error);
          this.props.show(
            'Something went wrong on our end. Please try again later.',
          );
        });
    } catch (error) {
      console.error('SEND MAIL ERROR:', error);
      alert('Please make sure you are signed in and try again later.');
    }
  };

  render() {
    const { loading, subject, text } = this.state;
    return (
      <Paper elevation={3} style={{ maxWidth: 600, padding: 50, width: 600 }}>
        <form
          onSubmit={this.handleSubmit}
          style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            required
            style={{ marginBottom: 30 }}
            onChange={(e) => this.setState({ subject: e.target.value })}
            placeholder="Subject"
            value={subject}
          />

          <TextField
            id="oulined-basic"
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            multiline
            required
            rows={8}
            style={{ marginBottom: 20 }}
            onChange={(e) => this.setState({ text: e.target.value })}
            placeholder="Your message here..."
            value={text}
          />

          {loading && <LinearProgress color="primary" />}

          <Button
            style={{ marginTop: 10 }}
            color="primary"
            variant="contained"
            type="submit">
            Send
          </Button>
        </form>
      </Paper>
    );
  }
}
