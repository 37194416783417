import React from 'react';
import { Backdrop, Button, Fade, Modal, Paper } from '@material-ui/core';

const CompleteModal = ({ close, message }) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    open={true}
    onClose={close}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={true}>
      <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
        <h3 style={{ textAlign: 'center' }}>{message}</h3>
        <hr style={{ width: '100%' }} />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={close}
            style={{ width: '80%' }}
          >
            CLOSE
          </Button>
        </div>
      </Paper>
    </Fade>
  </Modal>
);

export default CompleteModal;
