import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { CustomButton } from '../Custom/CustomForm';
import { auth, userRef } from '../../Api/FirebaseConfig';
import { CREATE_STRIPE_USER } from '../../Config';
import logo from '../../Assets/Authoritek_Color.png';
import styles from '../../Styles/Auth';

export default class SelectPaymentModal extends React.Component {
  /* Init our state. */
  state = {};

  /* Handle navigation */
  handleNavigation = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      const { paymentType } = this.state;

      // If a user selects stripe, we should create a session for them and send them to the customer portal.

      // If a user selects offline, we should just take them to their account screen.
      if (paymentType === 'Stripe') {
        this.callCreateCustomer();
      } else {
        userRef.doc(auth.currentUser.uid).update({ paymentOption: 'Offline' });
        this.props.history.push('/setup-completion');
      }
    });
  };

  /* Create a new session for the customer, call firebase function to add customerId to 
       customer in firebase then call the stripe session url endpoint. Then navigate user. */
  callCreateCustomer = async () => {
    // Call create customer in stripe
    const body = { user: auth.currentUser };
    const response = await fetch(CREATE_STRIPE_USER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        console.error(error);
      });

    // Return our session url and navigate the user to the customer portal.
    this.setState({ loading: false }, () =>
      window.open(response.sessionUrl, '_self'),
    );
  };

  render() {
    const { loading, paymentType } = this.state;

    return (
      <div style={styles.main}>
        <div style={styles.secondary}>
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifySelf: 'center',
              padding: 20,
              maxWidth: 600,
            }}>
            <form onSubmit={this.handleNavigation} style={styles.form}>
              <div style={styles.imgcontainer}>
                <img
                  src={logo}
                  alt="logo.png"
                  width="100%"
                  style={{ maxWidth: 600 }}
                  height="100"
                />
              </div>
              <h3>How would you like to setup billing?</h3>
              <h5>
                If you choose stripe, we can setup automatic monthly payments
                for your subscription. If you choose offline however, you'll be
                responsible for sending us your payments manually via check or
                cash.
              </h5>
              <RadioGroup
                aria-label="Payment Type"
                name="Payment Type"
                onChange={(e) => this.setState({ paymentType: e.target.value })}
                style={{
                  width: '100%',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                }}
                value={paymentType}>
                <FormControlLabel
                  value="Stripe"
                  control={<Radio />}
                  label="Stripe"
                />
                <FormControlLabel
                  value="Offline"
                  control={<Radio />}
                  label="Offline"
                />
              </RadioGroup>
              <hr style={{ width: '100%', marginBottom: 20 }} />
              {loading && (
                <LinearProgress
                  color="primary"
                  style={{ width: '100%', marginBottom: 20 }}
                />
              )}
              <CustomButton
                variant="contained"
                color="default"
                disabled={paymentType === undefined}
                type="submit"
                style={styles.loginbutton}>
                Next
              </CustomButton>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}
