/* eslint-disable eqeqeq */
import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { auth, storageRef, userRef } from '../../Api/FirebaseConfig';
import { CustomSnackbar } from '../Custom';
import { GenericSpinner } from '../Misc';
import { Colors } from '../../Config';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { getUser } from '../../Helpers/Data';

export default class UserAccount extends React.Component {
  state = {
    avatar: this.props.user.avatar ? this.props.user.avatar : null,
    user: this.props.user,
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleLeavePage);
    auth.onAuthStateChanged((user) => {
      if (user)
        if (!this.props.user || !this.props.user._id) this.getUserData(user);
    });
  }

  /* If no user was passed via props, get the user's uid from auth and make a Firestore query. */
  getUserData = async ({ uid }) => {
    const user = await getUser(uid);
    this.setState({ user });
  };

  handlePictureUpload = (files) => {
    this.setState({ loading: true }, () => this.uploadPicture(files));
  };

  uploadPicture = async (files) => {
    // We only want 1 image to be uploaded
    let image = files[0];

    const ref = storageRef.child(
      `/${this.props.user._id}/Uploads/${image.name}`,
    );

    const url = await ref.put(image).then((res) => {
      return ref.getDownloadURL().then((link) => {
        return link;
      });
    });

    if (url) {
      await userRef
        .doc(this.props.user._id)
        .update({ avatar: url })
        .then(() => {
          auth.currentUser.updateProfile({ photoURL: url });
          this.setState({ avatar: url, loading: false });
        })
        .catch((error) => {
          this.setState({ showError: true, loading: false, message: error });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  /* Save the changes to the user object in firestore */
  saveChanges = async ({ _id, name, phonenumber, webUrl }) => {
    if (
      name == this.props.user.name &&
      phonenumber == this.props.user.phonenumber &&
      webUrl == this.props.user.webUrl
    ) {
      return;
    }
    const updated = await userRef
      .doc(_id)
      .update({
        name,
        phonenumber,
        webUrl,
      })
      .then(() => {
        return 'SUCCESS';
      })
      .catch((error) => {
        return error;
      });

    if (updated === 'SUCCESS') {
      let message = 'Your changes have been saved.';
      this.setState({ message, showSnackbar: true });
    } else {
      console.error('Updating Error:', updated);
      this.setState({ showError: true });
    }
  };

  /* Handler to close any error modals we might have. */
  closeModals = () => {
    this.setState({ showError: false, showSnackbar: false });
  };

  /* If the user is about to leave while in edit mode, show them warning that their changes will 
     not be saved. */
  componentWillUnmount() {
    const { mode } = this.state;
    if (mode === 'Edit') {
      window.removeEventListener('beforeunload', this.handleLeavePage);
    }
  }

  /* Handle the attempt to leave. */
  handleLeavePage = (e) => {
    const confirmationMessage = 'WHOA THERE!';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  /* Listen for the auth change in an avatar or state update */
  listenForAvatarChange = () => {
    const { user } = this.props;
    const { avatar } = this.state;
    if (avatar) {
      return avatar;
    }
    if (auth.currentUser) {
      return !user.avatar ? auth.currentUser.photoURL : user.avatar;
    }
  };

  /* Render a different color based on the customer onboarding status. */
  renderStatus = (userStatus) => {
    if (userStatus) {
      if (userStatus === 'Started') {
        return Colors.red;
      } else if (userStatus === 'In Progress') {
        return Colors.gold;
      } else if (userStatus === 'Live') {
        return Colors.moss;
      }
    }
  };

  /* Change the message of the "mode" button based on what mode is selected. */
  renderToggledMode = () => {
    const { mode } = this.state;
    return mode === 'Edit' ? 'Save' : 'Edit';
  };

  /* Change the action of our "mode" button. */
  toggleActionButton = () => {
    const { mode, user } = this.state;
    mode === 'Edit'
      ? this.setState({ mode: 'normal' }, () => this.saveChanges(user))
      : this.setState({ mode: 'Edit' });
  };

  render() {
    const { user } = this.state;
    const { loading, message, mode, showSnackbar } = this.state;
    return (
      <Card style={{ marginBottom: 30, maxWidth: 600 }}>
        {/* <CardHeader title="Account" /> */}
        <CardContent>
          {/* Avatar stuff */}
          <h3>Avatar</h3>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <AvatarItem avatar={this.listenForAvatarChange()} />
              {/* <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.handlePictureUpload(e.target.files)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  color="primary"
                  component="span"
                  variant="outlined"
                  style={{ maxHeight: 30, margin: 5, fontWeight: "bold" }}
                >
                  Upload
                </Button>
              </label> */}
            </div>

            {/* Show loading animation when image is being processed. */}
            {loading && <GenericSpinner show={loading} />}
            {user && user.onboardingStatus && (
              <h4
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                Status:
                <h4
                  style={{
                    color: this.renderStatus(user.onboardingStatus),
                    marginLeft: 5,
                  }}>
                  {user.onboardingStatus}
                </h4>
              </h4>
            )}

            {/* This should be on the right side of our upload button. */}
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Actions
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Role"
                style={{ minWidth: 200 }}>
                {this.props.renderOptions()}
              </Select>
            </FormControl>
          </div>

          {/* Show name and business of user. */}
          <hr
            style={{
              color: '#FFF',
              backgroundColor: '#FFF',
              width: '100%',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                alignItems: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                paddingRight: 10,
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}>
                <h4>Display name</h4>
                <h4 style={{ fontWeight: 'normal' }}>
                  Visible to other members
                </h4>
              </div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled={mode !== 'Edit'}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    user: {
                      ...prevState.user,
                      name: e.target.value,
                    },
                  }))
                }
                placeholder={user.name}
                value={user.name}
              />
            </div>

            <div
              style={{
                alignItems: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingLeft: 10,
              }}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}>
                <h4>Business</h4>
                <h4 style={{ fontWeight: 'normal' }}>This cannot be changed</h4>
              </div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                onChange={(e) =>
                  this.setState((prevState) => ({
                    user: {
                      ...prevState.user,
                      business: e.target.value,
                    },
                  }))
                }
                placeholder={user.business}
              />
            </div>
          </div>

          <hr
            style={{
              color: '#FFF',
              backgroundColor: '#FFF',
              width: '100%',
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                alignItems: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                paddingRight: 10,
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}>
                <h4>Email address</h4>
                <h4 style={{ fontWeight: 'normal' }}>This cannot be changed</h4>
              </div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                onChange={(e) =>
                  this.setState((prevState) => ({
                    user: {
                      ...prevState.user,
                      email: e.target.value,
                    },
                  }))
                }
                placeholder={user.email}
              />
            </div>

            <div
              style={{
                alignItems: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 10,
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                }}>
                <h4>Phone number</h4>
              </div>

              <TextField
                placeholder="Phone number*"
                type="phonenumber"
                value={user.phonenumber}
                disabled={mode !== 'Edit'}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    user: {
                      ...prevState.user,
                      phonenumber: e.target.value,
                    },
                  }))
                }
                InputProps={{
                  inputComponent: PhoneNumberInputFormat,
                }}
                variant="outlined"
              />
            </div>
          </div>

          <hr
            style={{ color: '#FFF', backgroundColor: '#FFF', width: '100%' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                marginRight: 10,
              }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Website</h4>
              </div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled={mode !== 'Edit'}
                onChange={(e) =>
                  this.setState((prevState) => ({
                    user: {
                      ...prevState.user,
                      webUrl: e.target.value,
                    },
                  }))
                }
                value={user.webUrl}
                placeholder={user.webUrl}
              />
            </div>
          </div>

          <hr
            style={{
              color: '#FFF',
              backgroundColor: '#FFF',
              width: '100%',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              color="primary"
              disabled={user._id && auth.currentUser.uid !== user._id}
              variant="contained"
              onClick={() => this.toggleActionButton()}>
              {this.renderToggledMode()}
            </Button>
          </div>
        </CardContent>

        {/* Show error if it happens. */}
        {showSnackbar && (
          <CustomSnackbar
            close={() => this.closeModals()}
            message={message}
            open={showSnackbar}
          />
        )}
      </Card>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function AvatarItem(props) {
  const classes = useStyles();

  return (
    <Avatar
      alt="John Smith"
      className={classes.large}
      src={
        props.avatar
          ? props.avatar
          : '../../Assets/Authoritek_ProfileIcon_Large.png'
      }
    />
  );
}

const PhoneNumberInputFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####" //< Better user experience
    />
  );
};

PhoneNumberInputFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
