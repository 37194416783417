import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import Colors from '../../Styles/Colors';
import useAuth from '../../Hooks/useAuth';

const UserSubscriptionCard = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        className={classes.header}
        subheader={props.subheader}
        title={props.title}
      />
      <CardContent>
        {props.metadata && (
          <Typography className={classes.appName} component="h4">
            {props.metadata.appName}
          </Typography>
        )}
        {props.status.length > 0 && (
          <Typography className={classes.status} variant="body2" component="p">
            {props.status}
          </Typography>
        )}
        <Typography variant="body2" component="p">
          {props.description}
        </Typography>
      </CardContent>

      {user.role !== 'Customer' && (
        <CardActions className={classes.actions}>
          <Button size="small" onClick={() => props.action(props.item)}>
            <Typography className={classes.buttontext}>
              {props.actionName}
            </Typography>
          </Button>
          <Button size="small" onClick={() => props.edit(props.item)}>
            <Typography className={classes.buttontext}>
              {props.secondActionName}
            </Typography>
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  appName: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
  },
  root: {
    margin: 5,
    maxWidth: 400,
    minWidth: 275,
    borderColor: '#3366FF',
    borderWidth: 5,
    borderRadius: 3,
  },
  actions: {
    justifyContent: 'center',
  },
  buttontext: {
    fontWeight: 'bold',
  },
  header: {
    alignItems: 'center',
    backgroundColor: '#F4F6FA',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  status: {
    color: Colors.cyan,
    textAlign: 'center',
  },
  title: {
    fontSize: 14,
  },
});

export default UserSubscriptionCard;
