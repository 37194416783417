import React from "react";
import Help from "@material-ui/icons/Help";
import { Tooltip } from "@material-ui/core";

export default function GenericInfoIcon({ direction, show, text }) {
  return show ? (
    <Tooltip placement={direction} title={text}>
      <Help />
    </Tooltip>
  ) : (
    <div />
  );
}
