/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Paper, Switch } from '@material-ui/core';

import { getMetadata, updateMetadata } from '../../Helpers/Metadata';

import { GenericSnackbar } from '../Misc';
import useAuth from '../../Hooks/useAuth';

const MasterSettingsPanel = ({ history }) => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const { user } = useAuth();
  const myRole = user.role;

  useEffect(() => {
    myRole !== 'Admin' ? history.push('/dashboard') : getData();
  }, []);

  const closeModals = () => {
    setMessage('');
    setSnackbar(false);
  };

  /* Get all the metadata from firebase so we can toggle it. */
  const getData = async () => {
    const response = await getMetadata();
    setData(response);
  };

  /* Handle all of our state changes here. */
  const handleChange = (event) => {
    // TODO: This is a horrible implementation and we need to fix it.
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === event.target.name) {
        data[i].hidden = event.target.checked;
      }
    }

    setData(data);
  };

  /* Should we have a submit button at the bottom? Or just live-change the settings? */
  const handleSubmit = async () => {
    const updated = await updateMetadata(data);
    const msg =
      updated === 'SUCCESS' ? 'Your changes have been saved.' : updated;
    setMessage(msg);
    setSnackbar(true);
  };

  return (
    <Paper elevation={3} style={{ padding: 30 }}>
      {data && (
        <p>
          These switches will dis/allow Salespeople and Allies from seeing the
          following:
        </p>
      )}

      {data &&
        data.map((item, index) => (
          <div
            key={index}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Switch
              checked={item.hidden}
              key={index}
              name={item._id}
              onChange={handleChange}
            />
            <p>{item._id}</p>
          </div>
        ))}
      {data && (
        <Button color="primary" onClick={handleSubmit} variant="contained">
          SUBMIT
        </Button>
      )}
      {snackbar && (
        <GenericSnackbar
          close={closeModals}
          message={message}
          open={snackbar}
        />
      )}
    </Paper>
  );
};

export default MasterSettingsPanel;
