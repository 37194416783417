import {
  appRef,
  messagesRef,
  metaRef,
  subscriptionRef,
  userRef,
} from '../Api/FirebaseConfig';
import {
  DELETE_USER,
  EMAIL_DELETE_USER,
  GET_ALL_PRODUCTS,
  GIVE_USER_SUBSCRIPTION,
  REMOVE_USER_SUBSCRIPTION,
} from '../Config';

/* Get a single user from the system. */
export async function getUser(userId) {
  const user = await userRef.doc(userId).get();
  return user.data();
}

/* Simply return all users of the system. */
export async function getAllUsers() {
  const docdata = await userRef.orderBy('name').get();
  return docdata.docs;
}

/* Remove the selected user */
export async function removeUser(userId) {
  const body = { userId };

  return await fetch(DELETE_USER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}

/* Update the selected user's onboaring status. */
export async function updateUserStatus(userId, onboardingStatus) {
  return await userRef
    .doc(userId)
    .update({ onboardingStatus })
    .then(() => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}

/* Send an email to our team saying someone wants to delete their account. */
export async function emailRemoveUser(user) {
  const body = { user };
  return await fetch(EMAIL_DELETE_USER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}

/* Start subscription functions. */
export async function getSubscriptions() {
  return await fetch(GET_ALL_PRODUCTS, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response.products.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function giveUserSubscription(
  myRole,
  price,
  priceId,
  productDesc,
  productId,
  productName,
  userId,
) {
  const canView = await metaRef.doc('Subscriptions').get();
  if (!canView.data().hidden || myRole === 'Admin') {
    return await fetch(GIVE_USER_SUBSCRIPTION, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        price,
        priceId,
        productDesc,
        productId,
        productName,
        userId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        return response.message;
      })
      .catch((error) => {
        return error;
      });
  } else {
    return "You can't complete this operation.";
  }
}

/* Gets all of the user's subscriptions. */
export async function getUserSubscriptions(userId, status) {
  let userSubs = await subscriptionRef
    .where('_userId', '==', userId)
    .get()
    .then((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        if (
          status !== 'Valid' &&
          doc.data().status &&
          doc.data().status === 'Suspended'
        ) {
          console.log('Skipping ', doc.data()._id);
        } else if (
          status !== 'Valid' &&
          doc.data().status &&
          doc.data().status === 'Suspended'
        ) {
          temp.push(doc.data());
        } else if (!doc.data().status) {
          temp.push(doc.data());
        }
      });
      return temp;
    });

  for (let i = 0; i < userSubs.length; i++) {
    if (userSubs[i]._appId) {
      const app = (await appRef.doc(userSubs[i]._appId).get()).data();

      userSubs[i].metadata = app;
    }
  }

  return userSubs;
}

/* Remove a subscription from a user (keep it ) */
export async function removeUserSubscription(subscriptionItem, userId) {
  return await fetch(REMOVE_USER_SUBSCRIPTION, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      subscriptionItem,
      userId,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response.message;
    })
    .catch((error) => {
      return error;
    });
}

/* Edit the given user subscription. 
   This purpose is typically to add on an App name to the subscription or change the status. */
export async function editUserSubscription({
  _id,
  _subscriptionId,
  appName,
  appStatus,
}) {
  await subscriptionRef
    .doc(_subscriptionId)
    .update({
      _appId: _id,
      appName,
      appStatus,
    })
    .catch((error) => {
      return error;
    });

  return await appRef
    .doc(_id)
    .update({ _subscriptionId, appStatus })
    .then(() => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}

/* Get all available apps from firestore.
   An available app is not yet associated with a subscription. */
export async function getAvailableApps() {
  let temp = [];
  let docData = await appRef.where('_subscriptionId', '==', '').get();

  docData = docData.docs;

  for (let i = 0; i < docData.length; i++) {
    temp.push(docData[i].data());
  }
  return temp;
}

/* Delete a given message from the system permenately. */
export async function removeMessage({ _id }) {
  return await messagesRef
    .doc(_id)
    .delete()
    .then(() => {
      return 'SUCCESS';
    })
    .catch((error) => {
      return error;
    });
}
