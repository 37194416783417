import { Button } from '@material-ui/core';
import { auth } from '../../Api/FirebaseConfig';

export default function SignOutButton({ history }) {
  const signOutUser = async () => {
    await auth
      .signOut()
      .then(() => {
        history.push('/login');
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <Button
      color="secondary"
      onClick={signOutUser}
      style={{ width: '100%' }}
      variant="contained">
      Sign Out
    </Button>
  );
}
