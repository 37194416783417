import { createContext, useEffect, useReducer } from 'react';
import { auth } from '../Api/FirebaseConfig';
import { getUser } from '../Helpers/Data';

const initialState = {
  auth,
  isAuthenticated: false,
  isInitialized: true,
  user: { ...auth.currentUser, role: 'Customer' },
};

const AuthContext = createContext({
  ...initialState,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  currentUser: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  // signInWithEmailAndPassword: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateRole: () => Promise.resolve(),
});

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  } else if (action.type === 'UPDATE_ROLE') {
    const { role } = action.payload;
    const user = state.user;
    user.role = role;

    return {
      ...state,
      user,
    };
  }

  return state;
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /* Get the signed in user's data and save it to context. */
  useEffect(() => {
    /* Get the user's data. */
    const callGetData = async (userId) => {
      const ud = await getUser(userId);
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: ud,
        },
      });
    };

    /* Subscribe to auth events. */
    const unsub = auth.onAuthStateChanged((userObj) => {
      if (userObj) {
        callGetData(userObj.uid);
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: {},
          },
        });
      }
    });

    return () => {
      console.log('UNSUBBING');
      unsub();
    };
  }, []);

  const createUserWithEmailAndPassword = async (email, password) => {
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      );
      return user;
    } catch (e) {
      console.error('Create User Error:', e);
    }
  };

  const currentUser = async () => {
    return auth.currentUser;
  };

  // Login user
  const login = async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      const userData = await getUser(auth.currentUser.uid);
      return userData;
    } catch (e) {
      console.error('Login Error:', e);
      throw new Error(e);
    }
  };

  // Logout user
  const logout = async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.error('Logout Error:', e);
    }
  };

  // Update the user's visual data on the client.
  const updateProfile = async (name, avatar) => {
    try {
      const user = await auth.currentUser.updateProfile({
        displayName: name,
        photoURL: avatar,
      });
      return user;
    } catch (e) {
      console.error('Update Profile Error:', e);
    }
  };

  // Update the user's role
  const updateRole = async (role) => {
    dispatch({
      type: 'UPDATE_ROLE',
      payload: { role },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        createUserWithEmailAndPassword,
        currentUser,
        login,
        logout,
        updateProfile,
        updateRole,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
