/* eslint-disable eqeqeq */
import { useState } from 'react';
import { Button, LinearProgress, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Colors } from '../../Config';

export default function OnboardingBusinessForm(props) {
  const [email, setEmail] = useState(props.data.email ? props.data.email : '');
  const [name, setName] = useState(props.data.name ? props.data.name : '');
  const [password, setPass] = useState(
    props.data.password ? props.data.password : '',
  );
  const [password2, setPass2] = useState(
    props.data.password2 ? props.data.password2 : '',
  );
  const [phone, setPhone] = useState(props.data.phone ? props.data.phone : '');

  const handleStateUpdate = (val, type) => {
    switch (type) {
      case 'Email':
        setEmail(val);
        break;
      case 'Name':
        setName(val);
        break;
      case 'Password':
        setPass(val);
        break;
      case 'Password2':
        setPass2(val);
        break;
      case 'Phone':
        setPhone(val);
        break;
      default:
        console.log('Nothing should update');
    }
  };

  /* When we make our network request to save this user as a firebase user, show some loading. */
  const showLoading = (e) => {
    e.preventDefault();

    // Validate our fields are worthy of creating an account.
    if (password !== password2) {
      props.showSnackbar('Your passwords do not match!');
      return;
    }

    props.checkUserCreation(true);
    handleSubmit();
  };

  /* Save our state variables as an object and ship it up to the parent component to be saved. */
  const handleSubmit = () => {
    props.createUser({ email, name, password, phone });
  };

  return (
    <form onSubmit={showLoading} style={{ width: '80%' }}>
      <TextField
        fullWidth
        placeholder="Full Name"
        type="text"
        value={name}
        onChange={(e) => handleStateUpdate(e.target.value, 'Name')}
        variant="outlined"
        style={{ marginBottom: 10, marginTop: 10 }}
        required
      />
      <TextField
        fullWidth
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => handleStateUpdate(e.target.value, 'Email')}
        variant="outlined"
        style={{ marginBottom: 10 }}
        required
      />
      <TextField
        fullWidth
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => handleStateUpdate(e.target.value, 'Password')}
        variant="outlined"
        style={{ marginBottom: 10 }}
        required
      />
      <TextField
        fullWidth
        placeholder="Repeat Password"
        type="password"
        value={password2}
        color={
          password.length > 6 && password == password2 ? 'primary' : 'secondary'
        }
        inputProps={{
          style:
            password.length > 6 && password == password2
              ? { color: Colors.aqua }
              : { color: Colors.red },
        }}
        onChange={(e) => handleStateUpdate(e.target.value, 'Password2')}
        variant="outlined"
        style={{ marginBottom: 10 }}
        required
      />

      <TextField
        fullWidth
        placeholder="Phone number"
        type="phonenumber"
        value={phone}
        onChange={(e) => handleStateUpdate(e.target.value, 'Phone')}
        InputProps={{
          inputComponent: PhoneNumberInputFormat,
        }}
        variant="outlined"
        style={{ marginBottom: 10 }}
        required
      />
      {props.loading && <LinearProgress color="primary" />}
      <Button
        disabled={props.loading}
        variant="contained"
        style={{
          alignSelf: 'center',
          backgroundColor: Colors.moss,
          color: Colors.white,
          width: '100%',
        }}
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
}

const PhoneNumberInputFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
    />
  );
};

PhoneNumberInputFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
