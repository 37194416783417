import { AppBar, Avatar, Toolbar } from '@material-ui/core';
import { auth } from '../../Api/FirebaseConfig';
import ButtonNavigators from './ButtonNavigators';
import SearchBar from './SearchBar';

import { Colors } from '../../Config';
// import { AppDeliveryModal } from "../ActionModals";
import useAuth from '../../Hooks/useAuth';

const Header = (props) => {
  const { user } = useAuth();

  /* Show the user's avatar in the right corner of our header */
  const renderAvatar = () => {
    if (auth.currentUser) {
      return (
        <Avatar
          alt="Profile Image"
          src={user.avatar}
          style={{ cursor: 'pointer' }}
          onClick={navigateToProfile}
        />
      );
    }
  };

  const navigateToProfile = () => {
    props.history.push({
      pathname: '/profile',
      user: auth.currentUser,
    });
  };

  return (
    <AppBar
      position="relative"
      style={{ backgroundColor: '#FFF', width: '100%' }}>
      <Toolbar
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}>
        {renderAvatar()}
        <SearchBar />
        <ButtonNavigators history={props.history} />
        <h2 style={{ color: Colors.primary }}>{props.screen}</h2>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
