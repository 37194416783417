import { useEffect, useState } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from '@material-ui/core';
import { Colors } from '../../Config';
import { auth } from '../../Api/FirebaseConfig';
import useAuth from '../../Hooks/useAuth';

const UsersTable = (props) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);

  const { user } = useAuth();

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'business',
      numeric: false,
      disablePadding: false,
      label: 'Business',
    },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    {
      id: 'payment',
      numeric: false,
      disablePadding: false,
      label: 'Payment (offline/stripe)',
    },
  ];

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  /* Check if a given action should be disabled or not. */
  const checkIfDisabled = ({ affiliateId }) => {
    const myRole = user.role;
    if (myRole === 'Admin') {
      return false;
    }

    if (auth.currentUser) {
      return auth.currentUser.uid === affiliateId ? false : true;
    }

    return true;
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  /* Change the page number we're on. */
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  /* Change the amount of rows per page we're on. */
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  /* Navigate to the user profile screen. */
  const handleNavigation = (userObj) => {
    props.history.push({ pathname: '/profile', user: userObj });
  };

  /* Render an appropriate color based on a given status. */
  const renderColor = (status) => {
    if (status === 'In Progress') {
      return Colors.gold;
    } else if (status === 'Started') {
      return Colors.red;
    } else if (status === 'Live') {
      return Colors.moss;
    }
  };

  /* Helper for sorting our user data. */
  const sortHandler = (sortId) => {
    handleRequestSort('', sortId);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /* Sort a row based on the comparitor inputted. */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  return (
    <Paper style={{ padding: 30, width: '80%' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => sortHandler(headCell.id)}
                    style={{ fontWeight: 'bold' }}>
                    {headCell.label}
                    {/* {orderBy === headCell.id ? (
                        <span>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null} */}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(users, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                <TableRow
                  hover
                  style={
                    index % 2
                      ? {
                          backgroundColor: '#F6F6F6',
                        }
                      : {
                          backgroundColor: '#FFFFFF',
                        }
                  }
                  tabIndex={-1}
                  key={index}>
                  <TableCell
                    align="left"
                    component="th"
                    id={user._id}
                    scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell align="left">
                    <Link href={`mailto:${user.email}`} color="inherit">
                      {user.email}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{user.business}</TableCell>
                  <TableCell
                    style={{
                      color: renderColor(user.onboardingStatus),
                    }}
                    align="left">
                    {user.onboardingStatus}
                  </TableCell>
                  <TableCell align="left">{user.paymentOption}</TableCell>
                  <TableCell align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={checkIfDisabled(user)}
                      onClick={() => handleNavigation(user)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UsersTable;
