/* Default variables for users, placeholders. */
export const AVATAR =
  "https://firebasestorage.googleapis.com/v0/b/billing-portal-c099a.appspot.com/o/Authoritek%2FDefaults%2Fdefault-avatar-768x768.png?alt=media&token=a6e9da90-6cf0-4690-9769-7a4078b5c8b5";

/* All of our firebase function links below VV */
export const CREATE_USER =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/createUser";

/* Used by customers when signing up for stripe. */
export const CREATE_STRIPE_USER =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/createStripeCustomer";

/* Used by the new signup flow to create a stripe customer and customer portal session. */
export const CREATE_INIT_STRIPE_USER =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/createInitStripeCustomer";

/* Called to delete a user by the system. */
export const DELETE_USER =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/deleteUser";

/* Called only when a customer is attempting to delete their account. */
export const EMAIL_DELETE_USER =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/emailDeleteUser";

/* Called to get all stripe products. */
export const GET_ALL_PRODUCTS =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/getAllProducts";

/* Called to give a customer a new subscription. */
export const GIVE_USER_SUBSCRIPTION =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/giveUserSubscription";

/* Called to "remove" a subscription from a customer. */
export const REMOVE_USER_SUBSCRIPTION =
  "https://us-central1-billing-portal-c099a.cloudfunctions.net/removeUserSubscription";

/* Colors for our colorscheme */
export const Colors = {
  primary: "#3ABB94", // Authoritek green
  secondary: "#224091", // Authoritek aqua
  aqua: "#224091",
  black: "#121212",
  darkgray: "#4F4F4F",
  lightgray: "#E2E8ED",
  gray1: "#828282",
  gray2: "#BDBDBD",
  gray3: "#E0E0E0",
  gray4: "#F2F2F2",
  white: "#FFFFFF",
  red: "#BB3A3A",
  gold: "#F2C94C",
  moss: "#3ABB94",
  cyan: "#1ecbe1",
};
