import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { auth, userRef } from '../../Api/FirebaseConfig';
import styles from '../../Styles/Loading';

export default class Loading extends React.Component {
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // this.props.history.push("/home");
        this.checkPermissions(user);
      } else {
        this.props.history.push('/login');
      }
    });

    // Wait 2 seconds to unsubscribe to authentication listener.
    // setTimeout(() => {
    //   authCheck();
    // }, 2000);
  }

  checkPermissions = async (user) => {
    let data = await userRef.doc(user.uid).get();
    data = data.data();

    console.log('check permissions called, loading.js');

    if (data.role === 'Admin' || data.role === 'Salesperson') {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/profile');
    }
  };

  render() {
    return (
      <div style={styles.main}>
        <div style={styles.secondary}>
          <CircularProgress color="primary" />
        </div>
      </div>
    );
  }
}
