import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import {
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import Colors from '../../Styles/Colors';
import GenericInfoIcon from '../Misc/GenericInfoIcon';
import { storageRef } from '../../Api/FirebaseConfig';

export default function SetupAppInfo(props) {
  const [appName, setName] = useState(
    props.data.appName ? props.data.appName : '',
  );
  // const [colors, setColors] = useState(
  //   props.data.colors ? props.data.colors : ""
  // );

  const [color1, setColor1] = useState(
    props.data.color1 ? props.data.color1 : '#000000',
  );
  const [color2, setColor2] = useState(
    props.data.color2 ? props.data.color2 : '#000000',
  );

  const [imageName, setImageName] = useState(
    props.data.imageName ? props.data.imageName : '',
  );
  const [imageUrl, setImageUrl] = useState(
    props.data.imageUrl ? props.data.imageUrl : '',
  );

  const [imageProcessing, setImageProcessing] = useState(false);

  const [webUrl, setWeb] = useState(props.data.webUrl ? props.data.webUrl : '');

  const handleStateUpdate = (val, type) => {
    switch (type) {
      case 'AppName':
        setName(val);
        break;
      case 'Color1':
        setColor1(val);
        break;
      case 'Color2':
        setColor2(val);
        break;
      case 'ImageName':
        setImageName(val);
        break;
      case 'ImageUrl':
        setImageUrl(val);
        break;
      case 'WebUrl':
        setWeb(val);
        break;
      default:
        console.log('Nothing should update');
    }
  };

  /* Show some loading animation while the image is processing. */
  const handlePictureUpload = (files) => {
    setImageProcessing(true);
    uploadPicture(files);
  };

  /* Handle the image processing to firebase storage. */
  const uploadPicture = async (files) => {
    try {
      // We only want 1 image to be uploaded
      let image = files[0];

      let ref;
      if (appName.length > 1) {
        ref = storageRef.child(`/Apps/${appName}/${image.name}`);
      } else {
        ref = storageRef.child(`/Apps/${image.name}`);
      }

      const url = await ref.put(image).then(async () => {
        const link = await ref.getDownloadURL();
        return link;
      });

      setImageName(image.name);
      setImageProcessing(false);
      setImageUrl(url);
    } catch (error) {
      console.error('UPLOAD ERROR:', error);
      setImageProcessing(false);
      props.showSnackbar('Something went wrong with processing your image.');
    }
  };

  /* When we make our network request to save this user as a firebase user, show some loading. */
  const showLoading = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  /* Save our state variables as an object and ship it up to the parent component to be saved. */
  const handleSubmit = () => {
    const colorsArray = [color1, color2];

    props.callCompletionHandler({
      appName,
      colorsArray,
      imageName,
      imageUrl,
      webUrl,
    });
  };

  return (
    <form onSubmit={showLoading} style={{ width: '80%' }}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
        }}>
        <TextField
          fullWidth
          placeholder="App Name"
          type="text"
          value={appName}
          onChange={(e) => handleStateUpdate(e.target.value, 'AppName')}
          variant="outlined"
          style={{ marginBottom: 10, marginRight: 10, marginTop: 10 }}
          required
        />
        <GenericInfoIcon
          direction="right"
          show={true}
          text="How you want the app to be displayed in the app stores"
        />
      </div>

      <h3 style={{ color: Colors.darkgray }}>
        Please select the two main colors that best reflect your business's
        brand
      </h3>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <ChromePicker
          color={color1}
          onChange={(e) => handleStateUpdate(e.hex, 'Color1')}
          triangle="hide"
        />
        <div style={{ width: 20 }} />
        <ChromePicker
          color={color2}
          onChange={(e) => handleStateUpdate(e.hex, 'Color2')}
          triangle="hide"
        />
      </div>

      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 20,
          marginTop: 20,
          width: '100%',
        }}>
        {imageUrl && imageUrl.length > 0 && (
          <div
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: 10,
              padding: 5,
            }}>
            <img
              alt="app-icon"
              src={imageUrl}
              // onClick={() => this.enhanceImage()}
              style={{
                borderRadius: 10,
                cursor: 'pointer',
                height: 200,
                width: 200,
              }}
              height="200"
              width="200"
            />
          </div>
        )}

        <h3 style={{ color: Colors.darkgray }}>
          Please upload a the best image you have of your company logo
        </h3>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
          }}>
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            style={{
              backgroundColor: '#424242',
              display: 'none',
              width: '100%',
            }}
            onChange={(e) => handlePictureUpload(e.target.files)}
          />
          <label htmlFor="contained-button-file">
            <Button
              color="primary"
              component="span"
              variant="contained"
              style={{ fontWeight: 'bold', width: '100%' }}>
              Upload Image
            </Button>{' '}
          </label>
          {imageProcessing && (
            <CircularProgress color="primary" style={{ marginLeft: 20 }} />
          )}
        </div>
      </div>

      <h3 style={{ color: Colors.darkgray }}>
        Does your business have a website?
      </h3>
      <TextField
        fullWidth
        placeholder="Your website URL"
        value={webUrl}
        onChange={(e) => handleStateUpdate(e.target.value, 'WebUrl')}
        variant="outlined"
        style={{ marginBottom: 10 }}
      />
      {props.loading && <LinearProgress color="primary" />}

      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
          width: '100%',
        }}>
        <Button
          disabled={props.loading}
          variant="contained"
          style={{ width: '48%' }}
          color="primary"
          onClick={() =>
            props.saveAppInfoData({
              appName,
              colorsArray: [color1, color2],
              imageName,
              imageUrl,
              webUrl,
            })
          }>
          Back
        </Button>
        <Button
          disabled={props.loading}
          variant="contained"
          style={{
            backgroundColor: Colors.moss,
            color: Colors.white,
            width: '48%',
          }}
          type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
}
