import React from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import Lottie from "react-lottie";
import Animation from "../../Assets/Animations/Loading.json";
import styles from "../../Styles/Misc/GenericSpinner";

export default function GenericSpinner({ show }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={styles.modal}
      open={show}
      //   onClose={!show}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Fade in={show}>
        <Lottie height={300} options={defaultOptions} width={300} />
      </Fade>
    </Modal>
  );
}
