import React from "react";
import logo from "../../Assets/Authoritek_Color.png";

export default function Farewell(props) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: 600,
        }}
      >
        <img src={logo} alt="logo.png" style={{ maxWidth: 600 }} height="100" />
        <h3 style={{ textAlign: "center" }}>We're sorry to see you go!</h3>
        <h5 style={{ textAlign: "center" }}>
          Someone from our team will reach out to you and we will start to
          discontinue your app.
        </h5>
        <h5 style={{ textAlign: "center" }}>
          In the meantime, let your users know the app will be taken down so
          they can migrate over to your other services.
        </h5>
        <h5 style={{ textAlign: "center" }}>
          Thank you for your business. We appreciate your time with us.
        </h5>
        <h5 style={{ textAlign: "right" }}>- Your Authoritek Team</h5>
      </div>
    </div>
  );
}
