import React from "react";
import { Slide } from "@material-ui/core";
import { SelectPaymentModal } from "../../Components/Misc";

export default class Payment extends React.Component {
  render() {
    return (
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <SelectPaymentModal history={this.props.history} />
      </Slide>
    );
  }
}
