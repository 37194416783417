const styles = {
  modal: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
};

export default styles;
