import { Colors } from '../../Config';

const Footer = () => {
  const now = new Date();
  return (
    <div
      style={{
        alignSelf: 'center',
        bottom: 0,
        backgroundColor: Colors.darkgray,
        paddingTop: 0,
        marginTop: 'auto',
        width: '100%',
      }}
    >
      <div
        style={{
          alignSelf: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          paddingTop: 15,
        }}
      >
        <h3
          style={{
            color: Colors.white,
            marginTop: 0,
            marginBottom: 0,
            textAlign: 'center',
          }}
        >
          Phone: +1 (616) 344.0110
        </h3>
        <p
          style={{
            color: Colors.white,
            paddingBottom: '2%',
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 0,
          }}
        >
          Authoritek &copy; {now.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
