import React from 'react';
import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
} from '@material-ui/core';
import { editUserSubscription, getAvailableApps } from '../../Helpers/Data';

export default class EditSubscriptionModal extends React.Component {
  state = {
    apps: [],
    appName: '',
    appStatus: '',
    statuses: ['Started', 'In Progress', 'Live'],
  };

  componentDidMount() {
    this.getData();
  }

  /* Get all available apps (who don't have a subscription attached to them). */
  getData = async () => {
    const apps = await getAvailableApps();
    this.setState({ apps });
  };

  showLoading = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => this.handleSubmit());
  };

  handleSubmit = async () => {
    const { action, error, item } = this.props;
    const { appStatus, app } = this.state;
    // Make sure we have our proper data.
    const subscription = app;
    subscription.appStatus = appStatus;
    subscription._subscriptionId = item._id;
    const updated = await editUserSubscription(subscription);

    if (updated === 'SUCCESS') {
      const message = 'The subscription has been updated.';
      action(message);
    } else {
      error(updated);
    }
  };

  render() {
    const { close } = this.props;
    const { apps, appName, appStatus, loading, statuses } = this.state;
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={true}
        onClose={() => close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Paper elevation={3} style={{ maxWidth: 600, padding: 30 }}>
            <h3 style={{ textAlign: 'center' }}>
              Please select the app you wish to attach this subscription to.
            </h3>
            <h6 style={{ textAlign: 'center' }}>
              Once you press submit, the app will be updated and the customer
              can view it's status.
            </h6>
            <form onSubmit={this.showLoading}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {/* Show dropdown with all apps that aren't attached to a subscription. */}
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    App
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="App"
                    onChange={(e) =>
                      this.setState({
                        app: e.target.value,
                        appName: e.target.value,
                      })
                    }
                    required
                    style={{ minWidth: 300, marginBottom: 20 }}
                    value={appName}
                  >
                    {apps.map((app, index) => (
                      <MenuItem key={index} value={app}>
                        {app.appName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Status"
                    onChange={(e) =>
                      this.setState({ appStatus: e.target.value })
                    }
                    required
                    style={{ minWidth: 300, marginBottom: 20 }}
                    value={appStatus}
                  >
                    {statuses.map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <hr style={{ width: '100%' }} />
              {loading && (
                <LinearProgress color="primary" style={{ width: '100%' }} />
              )}
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => close()}
                  style={{ marginRight: 10, width: '80%' }}
                >
                  CLOSE
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: '80%' }}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </div>
            </form>
          </Paper>
        </Fade>
      </Modal>
    );
  }
}
