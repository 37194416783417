import { useEffect, useState } from 'react';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { LinearProgress, TextField } from '@material-ui/core';
import { GenericSnackbar } from '../Misc';

import cards from '../../Assets/credit-cards.png';
import './styles.css';

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}>
    {processing ? 'Processing...' : children}
  </button>
);

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(props.loading);
  const [cardName, setName] = useState('');

  const hideSnackbar = () => {
    setError('');
  };

  useEffect(() => {
    setProcessing(props.loading);
  }, [props.loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      setProcessing(true);

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement('cardNumber'),
      });

      if (payload.error) {
        console.error('STRIPE ERROR:', payload.error.message);
        setProcessing(false);
        setError(payload.error.message);
        return;
      } else {
        props.callCompletionHandler(payload.paymentMethod);
      }
    } catch (err) {
      console.error('CAUGHT ERROR:', err);
      setError(err);
      setProcessing(false);
    }
  };

  return (
    <form className="Form" onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.imgcontainer}>
        <img alt="credit-cards.svg" src={cards} style={styles.img} />
      </div>

      <div style={styles.textcontainer}>
        <p style={styles.text}>$150/month</p>
      </div>

      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}>
        <div style={styles.cardwrapper}>
          <CardNumberElement />
        </div>

        <div style={styles.cardnamecontainer}>
          <TextField
            // label="Cardholder Name"
            id="name"
            fullWidth
            type="text"
            required
            style={styles.namefield}
            placeholder="Cardholder Name"
            value={cardName}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>

      <div style={styles.datacontainer}>
        <div style={styles.expfield}>
          <CardExpiryElement />
        </div>
        <div style={styles.cvcfield}>
          <CardCvcElement />
        </div>
      </div>
      <div style={styles.textcontainer}>
        <p style={styles.text}>
          You will be charged in two weeks for your app. After that, there will
          be monthly payments for at least a year.{' '}
        </p>
      </div>
      {processing && <LinearProgress color="primary" />}
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Pay & Setup My Account
      </SubmitButton>

      {error && error.length > 1 && (
        <GenericSnackbar
          close={hideSnackbar}
          message={error}
          open={error.length > 1}
        />
      )}
    </form>
  );
}

const styles = {
  cardwrapper: {
    backgroundColor: '#f9f9f9',
    padding: 5,
    borderRadius: 10,
    marginRight: 2,
    marginBottom: 10,
    width: '100%',
    boxShadow: '1px 2px 1px #e8e8e8',
  },
  cardnamecontainer: {
    backgroundColor: '#f9f9f9',
    padding: 5,
    paddingBottom: 0,
    marginBottom: 10,
    borderRadius: 10,
    marginRight: 2,
    width: '100%',
    boxShadow: '1px 2px 1px #e8e8e8',
  },
  expfield: {
    backgroundColor: '#f9f9f9',
    padding: 5,
    borderRadius: 10,
    marginRight: 5,
    width: '100%',
    boxShadow: '1px 2px 1px #e8e8e8',
  },
  cvcfield: {
    backgroundColor: '#f9f9f9',
    padding: 5,
    borderRadius: 10,
    marginLeft: 5,
    width: '100%',
    boxShadow: '1px 2px 1px #e8e8e8',
  },
  form: {
    // backgroundColor: "#F6F6F6",
    // borderRadius: 5,
    padding: 10,
  },
  imgcontainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },
  img: {
    alignSelf: 'center',
    width: '40%',
  },
  namefield: {
    marginBottom: 13,
  },
  datacontainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    width: '100%',
  },
  textcontainer: {
    marginTop: 10,
  },
  text: { textAlign: 'center' },
};
