import { LoginModal } from '../../Components/Auth';
import Slide from '@material-ui/core/Slide';

const Login = ({ history, location }) => {
  let direction = 'left';
  if (location.state) {
    direction = location.state.direction ? location.state.direction : 'left';
  }

  return (
    <Slide direction={direction} in={true} mountOnEnter unmountOnExit>
      <LoginModal history={history} />
    </Slide>
  );
};

export default Login;
