import React from 'react';
import { Paper } from '@material-ui/core';
import { CustomButton } from '../Custom/CustomForm';
import { auth } from '../../Api/FirebaseConfig';
import logo from '../../Assets/Authoritek_Color.png';
import styles from '../../Styles/Auth';

export default class CompletedSetupForm extends React.Component {
  /* Init our state. */
  state = {};

  /* Handle Navigation */
  handleNavigation = (action) => {
    action === 'signout'
      ? this.signOutUser()
      : this.props.history.push('/profile');
  };

  /* Sign the user out and navigate them back to the login screen. */
  signOutUser = async () => {
    await auth
      .signOut()
      .then(() => {
        this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({ error, showErrorModal: true });
      });
  };

  render() {
    return (
      <div style={styles.main}>
        <div style={styles.secondary}>
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifySelf: 'center',
              padding: 20,
              maxWidth: 600,
            }}
          >
            <form onSubmit={this.handleNavigation} style={styles.form}>
              <div style={styles.imgcontainer}>
                <img
                  src={logo}
                  alt="logo.png"
                  width="100%"
                  style={{ maxWidth: 600 }}
                  height="100"
                />
              </div>
              <h2>You're all set!</h2>
              <h4>
                We'll finish setting up your account for you. Please keep in
                touch with your sales representative as they will communicate
                with you the current status of your app!
              </h4>
              <h4>
                If you want to change some of your account information, feel
                free to click "My Profile" below.
              </h4>
              <hr style={{ width: '100%' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {' '}
                <CustomButton
                  color="default"
                  onClick={() => this.handleNavigation('signout')}
                  style={{ width: '100%', marginRight: 20 }}
                  variant="contained"
                >
                  CLOSE
                </CustomButton>
                <CustomButton
                  color="default"
                  onClick={() => this.handleNavigation('profile')}
                  style={{ width: '100%' }}
                  variant="contained"
                >
                  My Profile
                </CustomButton>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}
