import { Header, SideBar } from '../../Components/Misc';
import { AnalyticsModule } from '../../Components/Analytics';

const Dashboard = ({ history }) => (
  <div style={styles.main}>
    <SideBar history={history} screen="Dashboard" />

    <div style={styles.headercontainer}>
      <Header history={history} screen="Dashboard" />
      <AnalyticsModule history={history} />
    </div>
  </div>
);

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },
  headercontainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
};

export default Dashboard;
