import React from "react";
import Button from "@material-ui/core/Button";

/* Generic button for doing things within the webapp. */
export default function GenericAddButton(props) {
  return (
    <Button
      color="primary"
      onClick={() => props.action(props.screen)}
      variant="outlined"
    >
      {props.text}
    </Button>
  );
}
