import { useState } from 'react';
import {
  Backdrop,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { auth } from '../../Api/FirebaseConfig';
import { AVATAR, CREATE_USER } from '../../Config';
import useAuth from '../../Hooks/useAuth';

const CreateUser = (props) => {
  const [business, setBusiness] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Stripe');
  const [phonenumber, setPhonenumber] = useState('');
  const [role, setRole] = useState('Customer');
  const [wixAlly, setWixAlly] = useState(false);

  const { user } = useAuth();

  /* Check if passwords are equal in real-time */
  const checkPasswordsMatch = () => {
    return password !== password2 ? false : true;
  };

  /* Check the authed user's role and determine which roles we can create. */
  const renderAccountTypes = () => {
    const myRole = user.role;

    if (myRole === 'Admin') {
      return [
        <MenuItem key={0} value="Admin">
          Admin
        </MenuItem>,
        <MenuItem key={1} value="Ally">
          Ally
        </MenuItem>,
        <MenuItem key={2} value="Customer">
          Customer
        </MenuItem>,
        <MenuItem key={3} value="Salesperson">
          Salesperson
        </MenuItem>,
      ];
    } else if (myRole === 'Ally') {
      return [
        <MenuItem key={4} value="Customer">
          Customer
        </MenuItem>,
        <MenuItem key={5} value="Salesperson">
          Salesperson
        </MenuItem>,
      ];
    }
  };

  /* Submit function for form */
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const affiliateId = auth.currentUser.uid;

      const stripeUser = paymentMethod === 'Stripe' ? true : false;

      if (password === password2) {
        const body = JSON.stringify({
          affiliateId,
          avatar: AVATAR,
          business,
          email,
          name,
          password,
          phonenumber,
          role,
          stripeUser,
          wixAlly,
        });

        await fetch(CREATE_USER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body,
        }).then((response) => {
          return response.json();
        });

        alert('success!');
        props.close();
      } else {
        // Show alert here saying passwords are not the same
        alert('Those passwords do not match');
      }
    } catch (error) {
      console.error('ERROR:', error);
    }
  };

  // TODO: add wixally checkbox to the create user form.
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={props.close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Fade in={true}>
        <Paper elevation={3} style={{ padding: 30, minWidth: 600 }}>
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <h3 style={{ textAlign: 'center' }}>Create A New User</h3>

            <TextField
              id="outlined-basic"
              variant="outlined"
              required
              type="text"
              value={name}
              placeholder="*Display Name"
              onChange={(e) => setName(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                label="Role"
                style={{ minWidth: 300, marginBottom: 20 }}>
                {renderAccountTypes()}
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              variant="outlined"
              required
              type="email"
              value={email}
              placeholder="*Email"
              onChange={(e) => setEmail(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />

            {!checkPasswordsMatch() && <h6>Your passwords do not match.</h6>}

            <TextField
              id="outlined-basic"
              variant="outlined"
              required
              type="password"
              value={password}
              placeholder="*Password"
              onChange={(e) => setPassword(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />

            <TextField
              id="outlined-basic"
              variant="outlined"
              required
              type="password"
              value={password2}
              placeholder="*Please repeat password"
              onChange={(e) => setPassword2(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              required
              type="text"
              value={business}
              placeholder="*Business Name"
              onChange={(e) => setBusiness(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />

            <h5>Will this user be paying with stripe?</h5>
            <RadioGroup
              aria-label="Payment Method"
              name="Payment Method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}>
              <FormControlLabel
                value="Stripe"
                control={<Radio />}
                label="Stripe"
              />
              <FormControlLabel
                value="Offline"
                control={<Radio />}
                label="Offline"
              />
            </RadioGroup>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}>
              <h5>Is this user a wix ally?</h5>
              <Checkbox
                checked={wixAlly}
                inputProps={{ 'aria-label': 'Is this user a wix ally?' }}
                onChange={(e) => setWixAlly(e.target.checked)}
              />
            </div>

            <TextField
              id="outlined-basic"
              variant="outlined"
              type="tel"
              value={phonenumber}
              placeholder="Phone number (optional)"
              onChange={(e) => setPhonenumber(e.target.value)}
              style={{ minWidth: 300, marginBottom: 20 }}
            />
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default CreateUser;
