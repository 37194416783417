import React from "react";

import { Header, SideBar } from "../../Components/Misc";
import { MasterSettingsPanel } from "../../Components/Admin";

export default function SiteSettings(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <SideBar history={props.history} screen="Settings" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header history={props.history} screen="Settings" />
        <MasterSettingsPanel history={props.history} />
      </div>
    </div>
  );
}
