import React from 'react';
import Colors from '../../Styles/Colors';

export default function FormSwitcher({ onChange, screen }) {
  return (
    <div style={{ width: '80%' }}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
        }}>
        <div
          onClick={() => onChange('Business Info')}
          style={{
            cursor: 'pointer',
          }}>
          <h4 style={{ color: Colors.aqua }}>Step 1</h4>
          <hr
            style={
              screen === 'Business Info'
                ? { borderColor: Colors.moss, color: Colors.moss }
                : { opacity: 0 }
            }
          />
        </div>
        <div
          onClick={() => onChange('App Info')}
          style={{ cursor: 'pointer', marginLeft: 20 }}>
          <h4 style={{ color: Colors.aqua }}>Step 2</h4>
          <hr
            style={
              screen === 'App Info'
                ? { borderColor: Colors.moss, color: Colors.moss }
                : { opacity: 0 }
            }
          />
        </div>
        <div
          onClick={() => onChange('Billing Info')}
          style={{ cursor: 'pointer', marginLeft: 20 }}>
          <h4 style={{ color: Colors.aqua }}>Step 3</h4>
          <hr
            style={
              screen === 'Billing Info'
                ? { borderColor: Colors.moss, color: Colors.moss }
                : { opacity: 0 }
            }
          />
        </div>
        <div
          onClick={() => onChange('Payment')}
          style={{ cursor: 'pointer', marginLeft: 20 }}>
          <h4 style={{ color: Colors.aqua }}>Step 4</h4>
          <hr
            style={
              screen === 'Payment'
                ? { borderColor: Colors.moss, color: Colors.moss }
                : { opacity: 0 }
            }
          />
        </div>
      </div>
      <hr />
    </div>
  );
}
