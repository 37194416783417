import React from "react";
import { Slide } from "@material-ui/core";
import { CompletedSetupForm } from "../../Components/Misc";

export default class CompletedSetup extends React.Component {
  render() {
    return (
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <CompletedSetupForm history={this.props.history} />
      </Slide>
    );
  }
}
