/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getAllUsers } from '../../Helpers/Data';
import { Button } from '@material-ui/core';
import { userRef } from '../../Api/FirebaseConfig';
import useAuth from '../../Hooks/useAuth';

// Import Components
import { Header, SideBar } from '../../Components/Misc';
import { CreateUser, UsersTable } from '../../Components/Users';

const Users = ({ history }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [staff, setStaff] = useState([]);
  const [users, setUsers] = useState([]);

  const { user } = useAuth();
  const myRole = user.role;

  /* Get users and display them on UI */
  const getUsers = async () => {
    const users = [];
    const staff = [];

    // Get all users from firestore
    const docs = await getAllUsers();

    for (let i = 0; i < docs.length; i++) {
      const Obj = docs[i].data();
      Obj.role === 'Customer' ? users.push(Obj) : staff.push(Obj);
    }

    setStaff(staff.length > 0 ? staff : []);
    setUsers(users.length > 0 ? users : []);
    // listenForUsers();
  };

  console.log('USERS PAR:', users);

  /* Listen for new users of the system to be added and append them to our list. */
  const listenForUsers = async () => {
    // Deconstruct our staff/users array
    userRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          let Obj = change.doc.data();

          // Check to see if we already added the user.
          const exists = users.some((el) => el._id === Obj._id);

          !exists && Obj.role === 'Customer'
            ? setUsers([...users, Obj])
            : console.log('We already have you...');

          !exists && Obj.role !== 'Customer'
            ? setStaff([...staff, Obj])
            : console.log('We already have you...');
        }
      });
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  /* Helper to close modals from this screen */
  const closeModals = () => {
    setShowUserModal(false);
  };

  /* Send authed user to the user profile screen */
  const sendToProfile = (userObj) => {
    history.push({
      pathname: '/profile',
      user: userObj,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
      }}>
      <SideBar history={history} screen="All Users" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}>
        <Header history={history} screen="All Users" />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginTop: 100,
            padding: 20,
            width: '90%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginBottom: 20,
              width: '80%',
            }}>
            <Button
              color="secondary"
              variant="contained"
              disabled
              onClick={() => setShowUserModal(true)}>
              Create User
            </Button>
          </div>
          <UsersTable action={sendToProfile} history={history} users={users} />

          {/* Show User Modals here */}
          {showUserModal && myRole !== 'Salesperson' && (
            <CreateUser close={closeModals} history={history} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
