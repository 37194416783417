import React from 'react';
import { analytics, auth } from '../../Api/FirebaseConfig';
import { colors, Fade, Paper, Slide } from '@material-ui/core';

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  FormSwitcher,
  OnboardingBusinessForm,
  SetupAppInfo,
  SetupCardBusinessInfo,
} from '../../Components/Onboarding';
import Background from '../../Assets/Background/SignupBackground.png';
import white_logo from '../../Assets/Logos/Authoritek_White.png';
// import { createUser } from "../../Helpers/Auth";
import { Colors, CREATE_INIT_STRIPE_USER } from '../../Config';
import { Footer, GenericSnackbar } from '../../Components/Misc';
import { CheckoutForm } from '../../Components/Stripe';
import { getQueryParams } from '../../Helpers/Parsers';

export default class SetupBusinessInfo extends React.Component {
  state = {
    loading: false,
    selectedTab: 'Business Info',
    showAppInfoModal: false,
    showBusinessModal: true,
  };

  /* Change screens and render different form data. */
  changeScreens = (selectedTab) => {
    this.setState({ selectedTab });
  };

  /* Check to see if the user has a firebase auth account, if they do, just save values to state.
     If they don't, create one so we can contact them later. */
  checkUserCreation = ({ email, name, password, password2, phone }) => {
    analytics.logEvent('user_filed_business_info');
    analytics.setUserProperties({ email, name, phone });
    this.setState({
      email,
      name,
      password,
      password2,
      phone,
      selectedTab: 'App Info',
    });
  };

  /* Save the app Info data to our screen's parent state in case they go back to change a business field. */
  saveAppInfoData = ({ appName, colorsArray, imageName, imageUrl, webUrl }) => {
    this.setState({ appName, colorsArray, imageName, imageUrl, webUrl }, () => {
      this.changeScreens('Billing Info');
    });
  };

  /* Save the card business info data to our parent state and move the user to the next tab. */
  saveAndMoveCardBusinessInfo = ({ addressState, city, street, zipcode }) => {
    this.setState({ addressState, city, street, zipcode }, () => {
      this.changeScreens('Payment');
    });
  };

  /* Transition the modal to toggle between forms. */
  showAppInfo = () => {
    this.setState({
      showBusinessModal: !this.state.showBusinessModal,
      showAppModal: !this.state.showAppModal,
    });
  };

  /* Save our CheckoutForm payment method data to the parent state. */
  showLoading = (paymentMethod) => {
    this.setState({ loading: true, paymentMethod }, () =>
      this.callCreateStripeSession(),
    );
  };

  /* Call our stripe session firebase function. */
  callCreateStripeSession = async () => {
    try {
      const {
        appName,
        addressState,
        city,
        colorsArray,
        email,
        imageName,
        imageUrl,
        name,
        password,
        paymentMethod,
        phone,
        street,
        webUrl,
        zipcode,
      } = this.state;

      if (
        !appName ||
        !addressState ||
        !city ||
        !colorsArray ||
        !email ||
        !imageName ||
        !imageUrl ||
        !name ||
        !password ||
        !paymentMethod ||
        !phone ||
        !street ||
        !webUrl ||
        !zipcode
      ) {
        this.setState({
          loading: false,
          message: 'Please double check your form fields and try again.',
          snackbar: true,
        });
        return;
      }

      analytics.logEvent('user_called_create_customer_endpoint');

      const affiliateId = getQueryParams()[0];
      const wixAlly = getQueryParams()[1];

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          affiliateId,
          addressState,
          appName,
          city,
          colorsArray,
          email,
          imageName,
          imageUrl,
          name,
          password,
          paymentMethodId: paymentMethod.id,
          phone,
          street,
          webUrl,
          wixAlly,
          zipcode,
        }),
      };

      const response = await fetch(CREATE_INIT_STRIPE_USER, options).then(
        (res) => {
          return res.json();
        },
      );

      console.log('Response:', response);
      console.log('message:', response.message);

      if (response.message.includes('FUNCTION FAILED')) {
        this.setState({
          loading: false,
          message: response.message,
          snackbar: true,
        });
        return;
      }

      /* Authenticate the user so when they come back, they can view their account info. */
      await auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          console.log('User Is Signed In.');
        })
        .catch((error) => {
          console.log('AUTH ERROR:', error);
          this.setState({ loading: false }, () => {
            this.props.history.push('/');
          });
        });

      /* Hide the loading UI and navigate the user to the stripe customer portal. */
      this.setState({ loading: false }, () => {
        this.props.history.push('/setup-completion');
      });
    } catch (error) {
      analytics.logEvent('failed_create_init_stripe_user');
      console.error('FETCH ERROR:', error);
      this.setState({ loading: false, message: error.message, snackbar: true });
      console.log('JSON:', JSON.stringify(error));
    }
  };

  /* Hide the snackbar */
  hideSnackbar = () => {
    this.setState({ message: '', snackbar: false });
  };

  /* Show the snackbar */
  showSnackbar = (message) => {
    this.setState({ message, snackbar: true });
  };

  render() {
    const { history } = this.props;
    const {
      addressState,
      appName,
      city,
      email,
      imageName,
      imageUrl,
      loading,
      message,
      name,
      password,
      password2,
      phone,
      selectedTab,
      snackbar,
      street,
      webUrl,
      zipcode,
    } = this.state;

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
      <div
        style={{
          alignItems: 'stretch',
          backgroundColor: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: '100vh',
          width: '100%',
        }}>
        <div
          style={{
            width: '100%',
          }}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              top: 10,
              left: '50%',
              right: '50%',
              backgroundColor: '#000',
            }}>
            <img src={white_logo} alt="white_logo.png" width="80" />
            <h3
              style={{
                color: Colors.white,
                textAlign: 'center',
                width: 350,
              }}>
              We’re so excited to build your app
            </h3>
          </div>

          <img
            src={Background}
            alt="SignupBackground.png"
            width="100%"
            height="300"
          />

          {/* Our absolute positioned stuff. */}
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginTop: -150,
                width: '100%',
              }}>
              <Paper
                elevation={3}
                style={{
                  alignItems: 'center',
                  alignSelf: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 20,
                  width: 600,
                  padding: 30,
                  marginBottom: 20,
                }}>
                <h3
                  style={{
                    color: Colors.darkgray,
                    textAlign: 'center',
                  }}>
                  {selectedTab}
                </h3>
                <FormSwitcher
                  onChange={this.changeScreens}
                  screen={selectedTab}
                />

                {selectedTab === 'Business Info' && (
                  <Fade in={selectedTab === 'Business Info'} timeout={3000}>
                    <OnboardingBusinessForm
                      checkUserCreation={this.checkUserCreation}
                      createUser={this.checkUserCreation}
                      data={{ email, name, password, password2, phone }}
                      history={history}
                      loading={loading}
                      showSnackbar={this.showSnackbar}
                    />
                  </Fade>
                )}
                {selectedTab === 'App Info' && (
                  <Fade in={selectedTab === 'App Info'} timeout={3000}>
                    <SetupAppInfo
                      callCompletionHandler={this.saveAppInfoData}
                      data={{ appName, colors, imageName, imageUrl, webUrl }}
                      history={history}
                      loading={loading}
                      showSnackbar={this.showSnackbar}
                    />
                  </Fade>
                )}
                {selectedTab === 'Billing Info' && (
                  <Fade in={selectedTab === 'Billing Info'} timeout={3000}>
                    <SetupCardBusinessInfo
                      callCompletionHandler={this.saveAndMoveCardBusinessInfo}
                      data={{ city, addressState, street, zipcode }}
                      history={history}
                      loading={loading}
                      saveAppInfoData={this.saveCardBusinessInfo}
                    />
                  </Fade>
                )}
                {selectedTab === 'Payment' && (
                  <Fade in={selectedTab === 'Payment'} timeout={3000}>
                    <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                        {({ elements, stripe }) => (
                          <CheckoutForm
                            callCompletionHandler={this.showLoading}
                            elements={elements}
                            history={history}
                            loading={loading}
                            stripe={stripe}
                          />
                        )}
                      </ElementsConsumer>
                    </Elements>
                  </Fade>
                )}
                {snackbar && (
                  <GenericSnackbar
                    close={this.hideSnackbar}
                    message={message}
                    open={snackbar}
                  />
                )}
              </Paper>
            </div>
          </Slide>
        </div>
        <Footer />
      </div>
    );
  }
}
