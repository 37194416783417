import React, { useState } from "react";
import { Button, Paper, Slide } from "@material-ui/core";
import logo from "../../Assets/Authoritek_Color.png";

export default function CustomerProcess(props) {
  const [screen, setScreen] = useState("Init");

  const renderScreen = () => {
    if (screen === "Init") {
      return <InitScreen next={() => changeScreenRender("Started")} />;
    } else if (screen === "Started") {
      return <StartedStatus next={() => setScreen("Onboarding")} />;
    } else if (screen === "Onboarding") {
      return <OnboardingStatus next={() => setScreen("Ready")} />;
    } else if (screen === "Ready") {
      return <ReadyForSale next={() => setScreen("Live")} />;
    } else if (screen === "Live") {
      return <Live next={() => setScreen("End")} />;
    } else {
      // Dismiss everything and go back to account.
      props.history.push("/profile");
    }
  };

  const changeScreenRender = (screen) => {
    setScreen(screen);
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      {renderScreen(screen)}
    </div>
  );
}

const InitScreen = (props) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} style={styles.paper}>
        <img src={logo} alt="logo.png" width="50%" />
        <p>
          This is our process for getting you up and running as soon as
          possible!
        </p>
        <hr style={styles.fullwidth} />
        <Button color="primary" variant="contained" onClick={props.next}>
          Next
        </Button>
      </Paper>
    </Slide>
  );
};

const StartedStatus = (props) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} style={styles.paper}>
        <img src={logo} alt="logo.png" width="50%" />
        <h5>Status: Started</h5>
        <p>
          First, your representative will either have you create an account or
          create one for you.
        </p>
        <p>
          At this point, your account status is "started". After that, we ask
          you upload all the necessary information in our app form.
        </p>
        <hr style={styles.fullwidth} />
        <Button color="primary" variant="contained" onClick={props.next}>
          Next
        </Button>
      </Paper>
    </Slide>
  );
};

const OnboardingStatus = (props) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} style={styles.paper}>
        <img src={logo} alt="logo.png" width="50%" />
        <h5>Status: Onboarding</h5>
        <p>
          After our team has all the info we need and you have paid the
          onboarding fee, we'll start to develop your app!
        </p>
        <p>
          You'll hear from your customer representative about the app once it's
          live on the store.
        </p>
        <hr style={styles.fullwidth} />
        <Button color="primary" variant="contained" onClick={props.next}>
          Next
        </Button>
      </Paper>
    </Slide>
  );
};

const ReadyForSale = (props) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} style={styles.paper}>
        <img src={logo} alt="logo.png" width="50%" />
        <h5>Status: Ready For Sale</h5>
        <p>
          Once our team gets the app live on the app stores, we'll need you to
          start paying your monthly subscription.
        </p>
        <p>
          Your representative will be in charge of adding that to your account,
          we ask that you add the credit card for this under "manage my billing"
          in your account screen.
        </p>
        <p>Once you're all paid up, you're all set to go!</p>
        <hr style={styles.fullwidth} />
        <Button color="primary" variant="contained" onClick={props.next}>
          Next
        </Button>
      </Paper>
    </Slide>
  );
};

const Live = (props) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper elevation={3} style={styles.paper}>
        <img src={logo} alt="logo.png" width="50%" />
        <h5>Status: Live</h5>
        <p>
          You're all set! Your monthly subscription is setup and your app is
          live!
        </p>
        <hr style={styles.fullwidth} />
        <Button color="primary" variant="contained" onClick={props.next}>
          Done
        </Button>
      </Paper>
    </Slide>
  );
};

const styles = {
  paper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: 20,
    maxWidth: "600",
    padding: 20,
  },
  fullwidth: {
    width: "100%",
  },
};
